import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faSquarePlus, faSquareMinus } from "@fortawesome/free-solid-svg-icons";
import { cartActions } from "../../store/cartSlice";
import { Row, Col, ListGroup, Image, Button, Card, Container, Form } from 'react-bootstrap';
import Navbar from "../Navs/Navs";
import Footer from "../../components/Footer/Footer";
import Allergy from "./Allergy"; // Make sure the path to Allergy component is correct
import styles from "./Cart.module.css";
import { FaTrash } from 'react-icons/fa';
import axios from 'axios';

const Cart = () => {
  const dispatch = useDispatch();
  const cartData = useSelector((state) => state.cart.cartData) || [];
  const subtotal = useSelector((state) => state.cart.total);
  const tps = useSelector((state) => state.cart.tps);
  const tvq = useSelector((state) => state.cart.tvq);
  const discount = useSelector((state) => state.cart.discount);
  const [paymentMethod, setPaymentMethod] = useState('credit');
  const [cartTrash, setCartTrash] = useState(null);
  const [allergyModal, setAllergyModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10));
  const [discountCode, setDiscountCode] = useState('');
  const userId = localStorage.getItem('userId');
  const [discountt, setDiscount] = useState({ code: '' });

  const toggleFavorite = (id) => {
    console.log("Toggle favorite status for: ", id);
  };

  const fetchCart = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/cart/get/cartTrashweb/by/user/${userId}`,
        { credentials: "include" }
      );
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message || responseData.error);
      } else {
        console.log('Response Data:', responseData);
        dispatch(cartActions.setCart(responseData));
        setCartTrash(responseData._id);
        localStorage.setItem("cartTrash", responseData._id);
      }
    } catch (err) {
      toast.error(err.message, {
        style: { border: "1px solid #FA8072", padding: "16px", color: "#FA8072" },
        iconTheme: { primary: "#FA8072", secondary: "#D6C7D4" },
      });
    }
  };

  const decreaseQuantityProductInCartOrder = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/cart/decrease/quantity/Cartweb/Order/${id}/${userId}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message || responseData.error);
      } else {
        toast.success(responseData.message, {
          style: { border: "1px solid #FA8072", padding: "16px", color: "#FA8072" },
          iconTheme: { primary: "#FA8072", secondary: "#D6C7D4" },
        });
        dispatch(cartActions.decreaseQuantity({ productId: id }));
      }
    } catch (err) {
      toast.error(err.message, {
        style: { border: "1px solid #FA8072", padding: "16px", color: "#FA8072" },
        iconTheme: { primary: "#FA8072", secondary: "#D6C7D4" },
      });
    }
  };

  const increaseQuantityProductInCartOrder = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/cart/increase/quantity/Cartweb/Order/${id}/${userId}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message || responseData.error);
      } else {
        toast.success(responseData.message, {
          style: { border: "1px solid #FA8072", padding: "16px", color: "#FA8072" },
          iconTheme: { primary: "#FA8072", secondary: "#D6C7D4" },
        });
        dispatch(cartActions.increaseQuantity({ productId: id }));
      }
    } catch (err) {
      toast.error(err.message, {
        style: { border: "1px solid #FA8072", padding: "16px", color: "#FA8072" },
        iconTheme: { primary: "#FA8072", secondary: "#D6C7D4" },
      });
    }
  };

  const clearCart = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/cart/clear/${userId}`,
        {
          method: "DELETE",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message || responseData.error);
      } else {
        toast.success(responseData.message, {
          style: { border: "1px solid #FA8072", padding: "16px", color: "#FA8072" },
          iconTheme: { primary: "#FA8072", secondary: "#D6C7D4" },
        });
        fetchCart();
      }
    } catch (err) {
      toast.error(err.message, {
        style: { border: "1px solid #FA8072", padding: "16px", color: "#FA8072" },
        iconTheme: { primary: "#FA8072", secondary: "#D6C7D4" },
      });
    }
  };

  const deleteProduct = async (productId) => {
    console.log("Attempting to delete product with ID:", productId);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/cart/delete/productweb/${productId}/${userId}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message || 'Failed to delete product');
      }
      toast.success('Product deleted successfully');
      fetchCart();
    } catch (error) {
      console.error('Error deleting product:', error);
      toast.error(error.message || 'Error deleting product');
    }
  };

  const applyDiscount = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/discount/getDiscount`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({ code: discountCode }),
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        throw new Error(responseData.message || 'Invalid discount code');
      }
  
      // Assurez-vous que la réduction est un nombre
      const discountPercentage = parseFloat(responseData.percentage) || 0;
  
      toast.success('Discount applied successfully');
      dispatch(cartActions.applyDiscount(discountPercentage));
    } catch (error) {
      console.error('Error applying discount:', error);
      toast.error(error.message || 'Error applying discount');
    }
  };
  

  useEffect(() => {
    console.log("Subtotal:", subtotal);
    console.log("TPS:", tps);
    console.log("TVQ:", tvq);
    console.log("Discount:", discount);
    console.log("Total:", calculateTotal());
  }, [subtotal, tps, tvq, discount, cartData]);
    
    

  useEffect(() => {
    fetchCart();
  }, []);
  const totalAfterDiscount = subtotal - (subtotal * (discount / 100));

  const restaurantId = localStorage.getItem('restaurantId'); // Récupérer l'ID du restaurant depuis le localStorage

  useEffect(() => {
    const fetchDiscount = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/discount/get-discount/${restaurantId}`);
        if (response.status === 200) {
          console.log('Discount Data:', response.data); // Inspect the data received
          setDiscount(response.data);
        } else {
          console.log('Aucun code de réduction disponible.');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération du code de réduction:', error);
      }
    };
    fetchDiscount();
  }, [restaurantId]);
  

  const calculateTotal = () => {
    if (!cartData.length || !cartData[0].productFK.length) return "0.00";
    
    // Calculate subtotal
    const subtotal = cartData[0].productFK.reduce((sum, item, index) => {
      const quantity = cartData[0].quantityProduct[index] || 0;
      const price = item.price || 0;
      return sum + (price * quantity);
    }, 0);
    
    // Apply discount if applicable
    const discountAmount = discount ? (subtotal * (discount / 100)) : 0;
    const subtotalAfterDiscount = subtotal - discountAmount;
    
    // Apply TPS and TVQ
    const tpsAmount =  (parseFloat(tps) || 0);
    const tvqAmount =  (parseFloat(tvq) || 0);
    
    // Calculate total
    const total = subtotal + tpsAmount + tvqAmount;
    
    return total;
  };
  
  const calculateTotall = () => {
    if (!cartData.length || !cartData[0].productFK.length) return "0.00";
    
    // Calculer le sous-total
    const subtotal = cartData[0].productFK.reduce((sum, item, index) => {
      const quantity = cartData[0].quantityProduct[index] || 0;
      const price = item.price || 0;
      return sum + (price * quantity);
    }, 0);
  
    // Ajouter TPS et TVQ
    const tpsAmount = parseFloat(tps) || 0;
    const tvqAmount = parseFloat(tvq) || 0;
  
    // Calculer la réduction
    const discountAmount = discount ? (subtotal * (discount / 100)) : 0;
    const subtotalAfterDiscount = subtotal - discountAmount;
  
    // Calculer le total après réduction
    const total = subtotalAfterDiscount + tpsAmount + tvqAmount;
  
    return total.toFixed(2); // Formater le total avec deux décimales
  };
  

  return (
    <>
      <Navbar />
      <Container className={styles.cartContainer + " my-4"}>
        <Row style={{ marginTop: "150px" }} className={styles.cartItems}>
          <Col md={8}>
            <ListGroup>
              {cartData.length > 0 && cartData[0].productFK.map((item, index) => (
                <ListGroup.Item key={item._id}>
                  <Row className="align-items-center">
                    <Col md={3}>
                      <FontAwesomeIcon
                        icon={solidHeart}
                        onClick={() => toggleFavorite(item._id)}
                        style={{ color: 'red', cursor: 'pointer' }}
                      />
                      <Image src={item.photo} className="img-fluid rounded-3" style={{ width: '60px', height: '60px' }} />
                    </Col>
                    <Col md={3}>
                      <div className="d-flex align-items-center">
                        <span style={{ whiteSpace: 'nowrap' }}>{item.name}</span>
                      </div>
                    </Col>
                    <Col md={2}>
                      <FontAwesomeIcon
                        icon={faSquareMinus}
                        style={{ color: 'salmon', width: '20px', height: '20px' }}
                        className={styles.quantityIcon}
                        onClick={() => decreaseQuantityProductInCartOrder(item._id)}
                      />
                      {cartData[0].quantityProduct[index]}
                      <FontAwesomeIcon
                        icon={faSquarePlus}
                        style={{ color: 'salmon', width: '20px', height: '20px' }}
                        className={styles.quantityIcon}
                        onClick={() => increaseQuantityProductInCartOrder(item._id)}
                      />
                    </Col>
                    <Col md={2}>${(item.price * cartData[0].quantityProduct[index]).toFixed(2)}</Col>
                    <Col md={2}>
                      <Button style={{ color: "salmon", backgroundColor: "white", borderColor: "white" }} onClick={() => deleteProduct(item._id)}> <FaTrash /> </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col md={4}>
            <Card className={styles.summaryCard}>
              <Card.Header>Summary</Card.Header>
              <Card.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col>TPS:</Col>
                      <Col>${tps}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>TVQ:</Col>
                      <Col>${tvq}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>Subtotal:</Col>
                      <Col>${calculateTotal()}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>Code de réduction:</Col>
                      <Col style={{ color: 'salmon' }}>{discountt.code}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                
                    <Row>
                      <Col>After reduction Total:</Col>
                      <Col>${calculateTotall()}</Col>
                    </Row>
               

                  </ListGroup.Item>
                </ListGroup>
                <Form inline className="mt-3">
                  <Form.Group controlId="discountCode">
                    <Form.Label>Discount Code:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter code"
                      value={discountCode}
                      onChange={(e) => setDiscountCode(e.target.value)}
                    />
                  </Form.Group>
                  <Button style={{ color: 'white', background: 'salmon', border: "white", marginRight: "20px" }} onClick={applyDiscount}>
                    Apply
                  </Button>
                </Form>
                <Button style={{ color: 'white', background: 'salmon', border: "white", marginTop: "15px" }} block onClick={() => setAllergyModal(true)}>
                  Go to Checkout
                </Button>
                <Button className="mt-3" style={{ color: 'white', background: 'salmon', border: "white" ,marginLeft:"30px"}} block onClick={clearCart}>
                  Clear Cart
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {allergyModal && <Allergy closeModal={() => setAllergyModal(false)} />}
      <Footer />
    </>
  );
};

export default Cart;