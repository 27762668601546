import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

const PayPalForm = ({ cartOrderId }) => {
  // Get the total from the Redux store
  const total = useSelector(state => state.cart.total);

  useEffect(() => {
    const renderPayPalButton = () => {
      if (window.paypal) {
        // Clear the container before rendering the button
        document.getElementById('paypal-button-container').innerHTML = '';

        window.paypal.Buttons({
          createOrder: async (data, actions) => {
            try {
              const response = await axios.post('http://localhost:5555/paypal/create-order', {
                amount: total,  // Use the total from the Redux store
                cartOrderId
              });
              return response.data.id; // PayPal order ID
            } catch (error) {
              console.error('Error creating PayPal order:', error);
            }
          },
          onApprove: async (data, actions) => {
            try {
              const response = await axios.get('http://localhost:5555/paypal/complete-order', {
                params: {
                  token: data.orderID
                }
              });
              console.log('Order captured:', response.data);
              // Redirect to the homepage after successful payment
              window.location.href = '/';
            } catch (error) {
              console.error('Error capturing PayPal order:', error);
            }
          }
        }).render('#paypal-button-container');
      }
    };

    renderPayPalButton();
  }, [total, cartOrderId]); // Make sure to include total as a dependency

  return (
    <div>
      <div id="paypal-button-container"></div>
    </div>
  );
};

export default PayPalForm;
