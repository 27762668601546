import React, { useState, useEffect } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import receiverAvatar from '../../../assets/4.jpg';
import ReactRecorder from './TimeRecorder';
import  Navbar from '../../Navs/Navs';
import Footer from '../../Footer/Footer';

const socket = io('https://socket.themenufy.com');

const ChatApp = () => {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [audioBlob, setAudioBlob] = useState(null);
  const currentUserId = localStorage.getItem('userId');
  const franchiseId = localStorage.getItem('franchiseFK');
  const [conversationId, setConversationId] = useState(null); // State to hold conversationId
  const [modalImage, setModalImage] = useState(null);
  const [imgFile, setImgFile] = useState(null);
  const [sidebarColor, setSidebarColor] = useState('linear-gradient(to right, #FA8072, rgba(250, 128, 114, 0.7))');
  const [mainChatColor, setMainChatColor] = useState('linear-gradient(to right, #fff,#CAD0D1)');
  const [chatHeaderColor, setChatHeaderColor] = useState('#FA8072');
  const [chatFooterColor, setChatFooterColor] = useState('#FA8072');
useEffect(() => {
  const fetchConversations = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/messages/conversations/${currentUserId}`);
      const fetchedConversations = await Promise.all(response.data.map(async (conversation) => {
        const participant = conversation.participants.find(participant => participant._id !== currentUserId);
        const participantId = participant ? participant._id : currentUserId;
        const ownerInfo = await fetchOwnerInfo(participantId);

        return {
          ...conversation,
          participantName: ownerInfo.userName,
          participantAvatar: `${process.env.REACT_APP_BACKEND_URL}user/getImageByUserId/${participantId}`,
        };
      }));

      fetchedConversations.sort((a, b) => {
       
        const aTime = new Date(a.lastMessageTimestamp).getTime();
        const bTime = new Date(b.lastMessageTimestamp).getTime();
        return bTime - aTime;
      });

      setConversations(fetchedConversations);
    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  };

  fetchConversations();
}, [currentUserId,conversations]);

  const fetchOwnerInfo = async (ownerId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/getById/${ownerId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching owner information:', error);
      throw error;
    }
  };

  useEffect(() => {
    socket.on('messagesSeen', ({ conversationId, entityId }) => {
      // Update UI to mark messages as seen
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.conversationId === conversationId && !msg.seenBy.map((seen) => seen.entityId).includes(entityId)
            ? { ...msg, seenBy: [...msg.seenBy, { entityId }] }
            : msg
        )
      );
    });

    return () => {
      socket.off('messagesSeen');
    };
  }, [conversationId]);

  const markMessagesAsSeen = (conversationId) => {
    socket.emit('markSeen', { conversationId, entityId: currentUserId });
    setMessages((prevMessages) =>
      prevMessages.map((msg) => {
        if (msg.senderId !== currentUserId && !msg.seenBy.some((seen) => seen.entityId === currentUserId)) {
          return { ...msg, seenBy: [...msg.seenBy, { entityId: currentUserId }] };
        }
        return msg;
      })
    );
  };

  const handleSelectConversation = (conversation) => {
    setSelectedConversation(conversation);
    setConversationId(conversation._id); // Set conversationId when a conversation is selected
   
    socket.emit('joinConversation', conversation._id);
    
  };
  useEffect(() => {
    if (selectedConversation) {
      fetchMessages(selectedConversation._id);
      
    }
  }, [selectedConversation]);
  const fetchMessages = (conversationId) => {
    socket.emit('fetchMessages', { conversationId });
  };

  useEffect(() => {
    if (selectedConversation) {
      socket.on('receiveMessages', (messages) => {
        setMessages(messages);
        markMessagesAsSeen(selectedConversation._id,messages);
      });
    }
    socket.on('messagesSeen', ({ conversationId, entityId }) => {
      if (conversationId === conversationId) {
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.conversationId === conversationId && !msg.seenBy.some((seen) => seen.entityId === entityId)
              ? { ...msg, seenBy: [...msg.seenBy, { entityId }] }
              : msg
          )
        );
      }
    });
    return () => {
      socket.off('receiveMessages');
      socket.off('messagesSeen');
    };
  
  }, [selectedConversation]);

  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      sendTextMessage();
    } else if (imgFile instanceof File) {
      sendImageMessage(imgFile);
    } else {
      console.warn('No valid message type found to send.');
    }
  };
  const sendAudioMessage = (audioBlob) => {
    const formData = new FormData();
    formData.append('audio', audioBlob, 'audioMessage.wav');
    formData.append('conversationId', conversationId);
    formData.append('senderId', currentUserId);
  
    fetch('/send-audio-message', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        socket.emit('sendMessage', data.data); 
      })
      .catch((error) => console.error('Error sending audio message:', error));
  };

  const sendTextMessage = () => {
    const message = {
      conversationId,
      senderId: currentUserId,
      text: newMessage,
    };

    fetch('/send-message', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(message),
    })
      .then((response) => response.json())
      .then((data) => {
        socket.emit('sendMessage', data.data);
        setNewMessage(''); 
      })
      .catch((error) => console.error('Error sending text message:', error));
  };
  const sendImageMessage = (imgFile) => {
    const formData = new FormData();
    formData.append('img', imgFile);
    formData.append('conversationId', conversationId);
    formData.append('senderId', currentUserId);

    fetch('/send-img-message', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        socket.emit('sendMessage', data.data);
      })
      .catch((error) => console.error('Error sending image message:', error));
  };
  const handleRecordingComplete = (audioBlob) => {
    sendAudioMessage(audioBlob);
  };
  useEffect(() => {
    setStyles({
      ...styles,
      sidebar: {
        ...styles.sidebar,
        background: sidebarColor,
      },
      mainChat: {
        ...styles.mainChat,
        background: mainChatColor,
      },
      chatHeader: {
        ...styles.chatHeader,
        background: chatHeaderColor,
      },
      chatFooter: {
        ...styles.chatFooter,
        background: chatFooterColor,
      },
    });
  }, [sidebarColor, mainChatColor, chatHeaderColor, chatFooterColor]);
  useEffect(() => {
    const updateWindowDimensions = () => {
      const windowHeight = window.innerHeight;
      const sidebarHeight = windowHeight;
      const mainChatHeight = windowHeight;

      setStyles({
        ...styles,
        sidebar: {
          ...styles.sidebar,
          height: `${sidebarHeight}px`,
        },
        mainChat: {
          ...styles.mainChat,
          height: `${mainChatHeight}px`,
        },
      });
    };
 
  
    updateWindowDimensions();

  
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);
  const handleImageClick = (imgSrc) => {
    setModalImage(imgSrc);
  };
  
  const closeModal = () => {
    setModalImage(null);
  };
  const [styles, setStyles] = useState({
    chatApp: {
      display: 'flex',
      height: '100vh',
      background: 'fff',
    },
    sidebar: {
      width: '30%',
      background: sidebarColor,
      color: 'white',
      overflowY: 'auto', 
      borderTopLeftRadius: '10px', 
      borderTopRightRadius: '10px', 
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',

    },
    conversationsList: {
      listStyle: 'none',
      padding: 0,
    },
    conversationItem: {
      cursor: 'pointer',
      padding: '10px',
      borderBottom: '1px solid #ccc',
      display: 'flex',
      alignItems: 'center',
      overflowY: 'auto',
      
    },

 mainChat: {
  display: 'flex',
  flexDirection: 'column',
  width: '70%',
  height: '100%', 
  background: mainChatColor,

  borderRadius: '15px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  position: 'relative',
  overflow: 'hidden',
  marginLeft :'20px',
  backgroundSize: 'cover', 
},
    chatWindow: {
      flex: 1,
      padding: '10px',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      
    },
    chatHeader: {
      position: 'sticky',
      top: 2,
      width: '100%', 
      padding: '10px',
      background: 'linear-gradient(to left, #FA8072, rgba(250, 128, 114, 0.7))',
      color: 'white',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
      animation: 'gradientBackground 3s infinite alternate',
      transition: 'background 0.5s ease',
      textAlign: 'center',
      zIndex: 1, 
      borderTopLeftRadius: '10px', 
  borderTopRightRadius: '10px', 

    },
    chatBody: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      paddingTop: '60px', 
      paddingBottom: '60px', 
      overflowY: 'auto',

    },
    messageList: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    chatFooter: {
      position: 'sticky',
      bottom: 0,
      width: '100%', 
      padding: '10px',
      background: 'linear-gradient(to right, rgba(250, 128, 114, 0.7),#FA8072)',
      display: 'flex',
      alignItems: 'center',
      borderTop: '1px solid rgba(255, 255, 255, 0.2)',
      animation: 'gradientBackground 3s infinite alternate',
      transition: 'background 0.5s ease',
      zIndex: 1, 
    },
    messageContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      marginBottom: '10px',
      position: 'relative',
    },
    message: {
      padding: '10px',
      borderRadius: '10px',
      maxWidth: '70%',
      marginBottom: '10px',
      alignSelf: 'flex-start',
    },
    sentMessage: {
      backgroundColor: '#78e08f',
      alignSelf: 'flex-start', // Align sent messages to the end (right)
      color: 'white',
    },
    receivedMessage: {
      backgroundColor: '#82ccdd',
      alignSelf: 'flex-end', 
      marginRight:"20px",
      color: 'black',
    },
    messageText: {
      marginLeft: '10px',
      color: 'black',
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
      maxWidth: '100%',
    },
    messageTime: {
      marginTop: '5px',
      fontSize: '10px',
      color: '#117E8B',
      textAlign: 'right',
      marginRight:"20px",
    },
    conversationInfo: {
      textAlign: 'center',
      fontSize: '18px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      margin: '0',
      fontFamily: 'Playfair Display, serif',
    },
    avatar: {
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      marginRight: '10px',
    },
    chatInput: {
      flex: 1,
      padding: '10px',
      borderRadius: '25px',
      border: 'none',
      marginRight: '10px',
      marginLeft: '10px',
    },
    sendButton: {
      padding: '10px 20px',
      backgroundColor: '#78e08f',
      color: 'white',
      border: 'none',
      borderRadius: '25px',
      cursor: 'pointer',
    },
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      zIndex: 950,
    },
    modalImage: {
      maxWidth: '90%',
      maxHeight: '90%',
    },
    customFileUpload: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      color: 'white',
      fontSize: '16px',
      marginRight: '10px',
      marginLeft:"10px"
    },
    customFileUploadIcon: {
      marginRight: '5px',
      fontSize: '24px',
    },
    themeOptions: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px',
      background: '#f0f0f0',
    },
    themeOption: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '0 10px',
    },
    colorInput: {
      width: '100px',
      height: '30px',
    },
  });
  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); 
      handleSendMessage();
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImgFile(file);
  };
  return (
    <>
    
    <Navbar/>
    <div style={{marginTop:"5%",marginBottom:"2%"}}>
    <div style={styles.themeOptions}>
        <div style={styles.themeOption}>
          <label>Sidebar</label>
          <input
            type="color"
            value={sidebarColor}
            onChange={(e) => setSidebarColor(e.target.value)}
            style={styles.colorInput}
          />
        </div>
        <div style={styles.themeOption}>
          <label>Main Chat</label>
          <input
            type="color"
            value={mainChatColor}
            onChange={(e) => setMainChatColor(e.target.value)}
            style={styles.colorInput}
          />
        </div>
        <div style={styles.themeOption}>
          <label>Header of Chat</label>
          <input
            type="color"
            value={chatHeaderColor}
            onChange={(e) => setChatHeaderColor(e.target.value)}
            style={styles.colorInput}
          />
        </div>
        <div style={styles.themeOption}>
          <label>Footer of Chat</label>
          <input
            type="color"
            value={chatFooterColor}
            onChange={(e) => setChatFooterColor(e.target.value)}
            style={styles.colorInput}
          />
        </div>
      </div>
    <div style={styles.chatApp}>
      
      <div style={{ ...styles.sidebar, background: sidebarColor }}>
        <ul style={styles.conversationsList}>
          {conversations.map((conversation) => (
            <li
              key={conversation._id}
              style={styles.conversationItem}
              onClick={() => handleSelectConversation(conversation)}
            >
              <img
                src={conversation.participantAvatar}
                alt="Avatar"
                style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '20px' }}
              />
              <div style={styles.conversationInfo}>
                <b><h2>{conversation.participantName}</h2></b>
                <p>{conversation.lastMessage}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div style={{ ...styles.mainChat, background: mainChatColor }}>
        {selectedConversation ? (
          <div style={styles.chatWindow}>
            <div style={{ ...styles.chatHeader, background: chatHeaderColor }}>
              <img
                src={selectedConversation.participantAvatar}
                alt="Avatar"
                style={{ width: '50px', height: '50px', borderRadius: '50%' }}
              />
              <h3>{selectedConversation.participantName}</h3>
            </div>
            <div style={styles.chatBody}>
              <div style={styles.messageList}>
                {messages.map((message, index) => {
                  const seenByEntities = message.seenBy ? message.seenBy.map(seen => seen.entityId) : [];
                  const isLastSeen = seenByEntities.includes(currentUserId) &&
                    (index === messages.length - 1 || !messages[index + 1].seenBy.some(seen => seen.entityId === currentUserId));
  
                  const isSender = message.senderId === currentUserId;
  
                  return (
                    <React.Fragment key={index}>
                      {index === 0 || messages[index - 1].senderId !== message.senderId ? (
                        <div
                          style={{
                            ...styles.messageContainer,
                            alignItems: isSender ? 'flex-start' : 'flex-end',
                          }}
                        >
                          <div
                            style={{
                              ...styles.conversationInfo,
                              ...(isSender ? {} : styles.conversationInfo),
                            }}
                          >
                            <div style={styles.conversationInfo}>
                              {isSender ? 'You' : selectedConversation.participantName}
                            </div>
                          </div>
                        </div>
                      ) : null}
  
                      <div
                        style={{
                          ...styles.messageContainer,
                          alignItems: isSender ? 'flex-start' : 'flex-end',
                        }}
                      >
                        <div
                          style={{
                            ...styles.message,
                            ...(isSender ? styles.sentMessage : styles.receivedMessage),
                          }}
                        >
                          <div style={styles.messageText}>
                            {message.text || (message.audioUrl && <audio controls src={message.audioUrl} />)}
                          </div>
                          {message.img && (
                            <img
                              src={`${message.img}`}
                              width="200"
                              height="200"
                              onClick={() => handleImageClick(message.img)}
                            />
                          )}
                        </div>
                        <span style={styles.messageTime}>
                          {new Date(message.createdAt).toLocaleTimeString()}
                        </span>
                        {isLastSeen && (
                          <img
                            src={receiverAvatar}
                            alt="Seen Avatar"
                            style={{ ...styles.avatar, position: 'absolute', right: '10px', bottom: '5px' }}
                          />
                        )}
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
            <div style={{ ...styles.chatFooter, background: chatFooterColor }}>
              <ReactRecorder
                audioBlobCallback={(blob) => setAudioBlob(blob)}
                onRecordingComplete={handleRecordingComplete}
              />
              <input
                type="file"
                accept="image/*"
                id="image-upload"
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
              <label htmlFor="image-upload" style={styles.customFileUpload}>
                <i className="fas fa-image" style={styles.customFileUploadIcon}></i>
              </label>
              <input
                type="text"
                placeholder="Type your message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={handleKeyDown}
                style={styles.chatInput}
              />
              <button onClick={() => handleSendMessage(audioBlob)}>
                <span className="input-group-text send_btn"><i className="fas fa-location-arrow"></i></span>
              </button>
            </div>
          </div>
        ) : (
          <div style={{ ...styles.chatWindow, textAlign: 'center', paddingTop: '50px' }}>
            <p style={{ color: 'black', fontSize: '18px' }}>Select a conversation to start chatting</p>
          </div>
        )}
      </div>
      {modalImage && (
        <div style={styles.modal} onClick={closeModal}>
          <img src={modalImage} alt="Zoomed" style={styles.modalImage} />
        </div>
      )}
    </div>
    </div>
    <Footer ></Footer>
    </>
  );
  
  
};

export default ChatApp;