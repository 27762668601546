import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Navs from "../../Navs/Navs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import styles from "./aboutUs.module.css"; // Assurez-vous de créer ce fichier CSS pour les styles spécifiques
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
function About() {
  const { restaurantId } = useParams();
  const [restaurantData, setRestaurantData] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    const fetchRestaurant = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/restaurant/retrieve/${restaurantId}`
        );
        setRestaurantData(response.data);
        console.log(response.data); // Debugging: Check the structure of restaurantData
      } catch (error) {
        console.error("Error fetching restaurant details:", error);
      }
    };
    fetchRestaurant();
  }, [restaurantId]);
  const [translatedRestoToArabe, setTranslatedRestoToArabe] = useState([null]);
  const [translatedRestoToFrench, setTranslatedProductsToFrench] = useState([
    null,
  ]);

  const [translatedRestoToEnglish, setTranslatedProductsToEnglish] = useState([
    null,
  ]);

  const i18nextLng = localStorage.getItem("i18nextLng");

  const fetchToArabeResto = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/restaurant/retrieve/translate/arabic/${restaurantId}`
        )
        .then((res) => {
          setTranslatedRestoToArabe(res?.data);
          localStorage.setItem("language", "ar");
          localStorage.setItem("i18nextLng", "ar");
          // window.location.reload();
        });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchToFrenchResto = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/restaurant/retrieve/translate/french/${restaurantId}`
        )
        .then((res) => {
          setTranslatedProductsToFrench(res?.data);
          localStorage.setItem("language", "fr");
          localStorage.setItem("i18nextLng", "fr");
          // window.location.reload();
        });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchToEnglishResto = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/restaurant/retrieve/translate/english/${restaurantId}`
        )
        .then((res) => {
          setTranslatedProductsToEnglish(res?.data);
          localStorage.setItem("language", "en");
          localStorage.setItem("i18nextLng", "en");
          // window.location.reload();
        });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchByLanguage = () => {
    if (i18nextLng === "ar") {
      fetchToArabeResto();
    } else if (i18nextLng === "en") {
      fetchToEnglishResto();
    } else {
      fetchToFrenchResto();
    }
  };

  useEffect(() => {
    fetchByLanguage();
  }, [restaurantId]);

  useEffect(()=>{fetchByLanguage();},[]);

  return (
    <div>
      <Navs
        fetchToArabe={fetchToArabeResto}
        fetchToFrench={fetchToFrenchResto}
        fetchToEnglish={fetchToEnglishResto}
      />

      {restaurantData && (
        <div
          className={styles.homeContainer}
          style={{
            backgroundImage: `url(${restaurantData.images})`,
            marginTop: 0,
          }}
        >
          {i18nextLng === "en" && (
            <div className={styles.homeBannerContainer}>
              {translatedRestoToEnglish.map((item, index) => (
                <div key={index} className={styles.homeTextSection}>
                  <div className={styles.logoContainer}>
                    <img
                      style={{ borderRadius: 23 }}
                      src={`${process.env.REACT_APP_BACKEND_URL}/uploads/resto/${restaurantData.logo}`}
                      alt="Logo"
                      className={styles.logo}
                    />
                  </div>
                  <h1 className={styles.primaryHeading}>{item?.nameRes}</h1>
                  <p className={styles.primaryText}>
                    {t("about.cuisineType")} : {item?.cuisineType}
                  </p>
                  <div className={styles.addressContainer}>
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className={styles.addressIcon}
                    />
                    <p className={styles.primaryText}>{item?.address}</p>
                  </div>
                  <p className={styles.primaryText}>
                    {/* {item.status === "closed"
                  ? t("about.closed")
                  : t("about.open")} */}
                    {item?.status}
                  </p>

                  {/* Display working hours */}
                </div>
              ))}
            </div>
          )}
          {i18nextLng === "fr" && (
            <div className={styles.homeBannerContainer}>
              {translatedRestoToFrench.map((item, index) => (
                <div key={index} className={styles.homeTextSection}>
                  <div className={styles.logoContainer}>
                    <img
                      src={`${process.env.REACT_APP_BACKEND_URL}/uploads/resto/${restaurantData.logo}`}
                      alt="Logo"
                      style={{ borderRadius: 23 }}
                      className={styles.logo}
                    />
                  </div>
                  <h1 className={styles.primaryHeading}>{item?.nameRes}</h1>
                  <p className={styles.primaryText}>
                    {t("about.cuisineType")} : {item?.cuisineType}
                  </p>
                  <div className={styles.addressContainer}>
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className={styles.addressIcon}
                    />
                    <p className={styles.primaryText}>{item?.address}</p>
                  </div>
                  <p className={styles.primaryText}>
                    {/* {item.status === "closed"
                    ? t("about.closed")
                    : t("about.open")} */}
                    {item?.status}
                  </p>

                  {/* Display working hours */}
                </div>
              ))}
            </div>
          )}
          {i18nextLng === "ar" && (
            <div className={styles.homeBannerContainer}>
              {translatedRestoToArabe.map((item, index) => (
                <div key={index} className={styles.homeTextSection}>
                  <div className={styles.logoContainer}>
                    <img
                      src={`${process.env.REACT_APP_BACKEND_URL}/uploads/resto/${restaurantData.logo}`}
                      alt="Logo"
                      style={{ borderRadius: 23 }}
                      className={styles.logo}
                    />
                  </div>
                  <h1 className={styles.primaryHeading}>{item?.nameRes}</h1>
                  <p className={styles.primaryText}>
                    {t("about.cuisineType").toUpperCase()} : {item?.cuisineType}
                  </p>
                  <div className={styles.addressContainer}>
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className={styles.addressIcon}
                    />
                    <p className={styles.primaryText}>{item?.address}</p>
                  </div>
                  <p className={styles.primaryText}>
                    {/* {item.status === "closed"
                      ? t("about.closed")
                      : t("about.open")} */}
                    {item?.status}
                  </p>

                  {/* Display working hours */}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default About;
