import React, { useState, useEffect } from "react";
import axios from "axios";
import Dish from "../search/Dish"; // Import the Dish component
import SubHeading from "../../Menu/SubHeading";
import { useParams } from "react-router-dom";
import backgroundImage from "../../../assets/full-bg.png";
import "./Dishes.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { toast } from "react-hot-toast"; // Ensure you use react-hot-toast
import { useSelector } from "react-redux"; // Import useSelector for Redux state
import { Link, useNavigate } from "react-router-dom";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import Navs from "../../Navs/Navs";
function Dishes(props) {
  const { restaurantId } = useParams();
  const userId = localStorage.getItem("userId");
  const [categories, setCategories] = useState([]);
  const [menuName, setMenuName] = useState("");
  const [menuId, setMenuId] = useState("");
  const [productList, setProductList] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [restaurantData, setRestaurantData] = useState(null);
  const [priceRange, setPriceRange] = useState([0, 100]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const [safeProducts, setSafeProducts] = useState([]);
  const [error, setError] = useState(null);
  const [restaurantStatus, setRestaurantStatus] = useState("open");
  const cartData = useSelector((state) => state.cart);
  const tableNb = useSelector((state) => state.restaurant.tableNb);

  // Access Redux state

  // Store restaurant ID in local storage when the component is mounted

  useEffect(() => {
    if (restaurantId) {
      console.log("Restaurant ID from URL:", restaurantId);
      localStorage.setItem("restaurantId", restaurantId);
    }
  }, [restaurantId]);

  const chunkProductList = (arr, size) => {
    return arr.reduce(
      (acc, _, i) => (i % size ? acc : [...acc, arr.slice(i, i + size)]),
      []
    );
  };
  console.log("Restaurant ID:", restaurantId);
  // const [translatedProductsToArabe, setTranslatedProductsToArabe] = useState([

  // ]);
  // const [translatedProductsToFrench, setTranslatedProductsToFrench] = useState([

  // ]);

  // const [translatedProductsToEnglish, setTranslatedProductsToEnglish] =
  //   useState([null]);
  // const fetchToArabe_ = async () => {
  //   try {
  //     await axios
  //       .post(`${process.env.REACT_APP_BACKEND_URL}/product/translate/arabic`)
  //       .then((res) => {
  //         setTranslatedProductsToArabe(res?.data);
  //         localStorage.setItem("language", "ar");
  //         localStorage.setItem("i18nextLng", "ar");
  //         // window.location.reload();
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // const fetchToFrench_ = async () => {
  //   try {
  //     await axios
  //       .post(`${process.env.REACT_APP_BACKEND_URL}/product/translate/french`)
  //       .then((res) => {
  //         setTranslatedProductsToFrench(res?.data);
  //         localStorage.setItem("language", "fr");
  //         localStorage.setItem("i18nextLng", "fr");
  //         // window.location.reload();
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // const fetchToEnglish_ = async () => {
  //   try {
  //     await axios
  //       .post(`${process.env.REACT_APP_BACKEND_URL}/product/translate/english/`)
  //       .then((res) => {
  //         setTranslatedProductsToEnglish(res?.data);
  //         localStorage.setItem("language", "en");
  //         localStorage.setItem("i18nextLng", "en");
  //         // window.location.reload();
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/menu/retrieve/by/resto/${restaurantId}`
        );
        const menuData = response.data;
        if (menuData && menuData.length > 0) {
          const menu = menuData[0]; // Access the first menu in the array
          setMenuName(menu.name);
          setMenuId(menu._id); // Get the menu ID
        }
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };
    fetchMenuData();
  }, [restaurantId]);

   useEffect(() => {
     const fetchCategories = async () => {
       try {
         if (!menuId) {
           console.error("Menu ID is missing");
          return;
        }
       const response = await axios.get(
         `${process.env.REACT_APP_BACKEND_URL}/category/find/item/by/menus/${menuId}`
         );
         setCategories(response.data);
       } catch (error) {
         console.error("Error fetching categories:", error);
       }
     };
     fetchCategories();
     props.fetchCategoriesAr();
     props.fetchCategoriesFr();
     props.fetchCategoriesEn();
     
   }, [menuId]);

  const i18nextLng = localStorage.getItem("i18nextLng");

  useEffect(() => {
    const fetchProductListByMenu = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/product/retrieveallbymenu/${menuId}`
        );
        setProductList(response.data.data);
      } catch (error) {
        console.error("Error fetching product list by menu:", error);
      }
    };
    if (menuId) {
      fetchProductListByMenu();
    }
  }, [menuId]);

  const fetchAllProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/product/retrieveallbymenu/${menuId}`
      );
      setProductList(response.data.data);
    } catch (error) {
      console.error("Error fetching all products:", error);
    }
  };

  const handleCategoryClick = async (categoryFK) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/product/retrieve/enabled/products/category/${categoryFK}`
      );
      const products = response.data;
      console.log("Products associated with the category:", products);
      setProductList(products);
    } catch (error) {
      console.error("Error fetching products by category:", error);
    }
  };

  useEffect(() => {
    const fetchRestaurant = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/restaurant/retrieve/${restaurantId}`
        );
        setRestaurantData(response.data);
      } catch (error) {
        console.error("Error fetching restaurant details:", error);
      }
    };
    fetchRestaurant();
  }, [restaurantId]);

  const handlePriceFilter = (range) => {
    setPriceRange(range);
    const filteredProducts = productList.filter(
      (product) => product.price >= range[0] && product.price <= range[1]
    );
    setProductList(filteredProducts);
  };

  const totalPages = Math.ceil(productList.length / itemsPerPage);
  const currentPageData = productList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  console.log("Backend URL:", process.env.REACT_APP_BACKEND_URLLL);
  useEffect(() => {
    const fetchSafeProducts = async () => {
      if (!userId || !menuId) {
        console.warn(
          "UserId or menuId is missing. Cannot fetch safe products."
        );
        return;
      }

      const requestUrl = `${process.env.REACT_APP_BACKEND_URLLL}/recommend/${userId}/${menuId}`;
      console.log("Requesting URL:", requestUrl);

      try {
        const response = await axios.get(requestUrl);

        if (response.status === 200) {
          setSafeProducts(response.data.safe_products || []);
        } else {
          console.error(
            `Failed to fetch safe products: ${response.status} ${response.statusText}`
          );
          setError(
            `Failed to fetch safe products: ${response.status} ${response.statusText}`
          );
        }
      } catch (err) {
        console.error("Fetch error:", err.message);
        setError(`Fetch error: ${err.message}`);
      }
    };

    fetchSafeProducts();
  }, [userId, menuId]);

  const addToCart = async (productId) => {
    const userId = localStorage.getItem("userId") || "000000000000000000000000";
    const storedRestaurantId = localStorage.getItem("restaurantId");

    if (!storedRestaurantId) {
      console.error("Restaurant ID is missing");
      toast.error("Restaurant ID is missing");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/cart/addtocartweb/${userId}`,
        {
          productFK: productId, // Utilisation de l'ID du produit
          ingredientFK: [],
          itemsFK: [],
          tableNb: tableNb,
          restaurantFK: storedRestaurantId,
        },
        { withCredentials: true }
      );

      if (response.status === 200) {
        toast.success("Product added to cart!");
        // Mettez à jour les éléments du panier ici, si nécessaire
      } else {
        throw new Error("Failed to add product to cart");
      }
    } catch (error) {
      console.error("Error adding product to cart:", error);
      toast.error(
        error.message || "An error occurred while adding product to cart"
      );
    }
  };

  const { t } = useTranslation();

  return (
    <section className="py-3 py-md-5 py-xl-8">
      {/* <Navs fetchToArabe={props.fetchToArabe_} fetchToEnglish={fetchToEnglish_} fetchToFrench={fetchToFrench_} /> */}
      {restaurantData && (
        <div
          className="home-container"
          style={{ backgroundImage: "white", marginTop: 0 }}
        >
          <div
            className="app__specialMenu flex__center section__padding"
            id="menu"
          >
            <div
              className="app__specialMenu-title"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                marginTop: "50px",
              }}
            >
              <SubHeading title="Menu that fits your palette" />
              <h1 className="headtext__cormorant">
                {t("dish.subTitle")} in {menuName}
              </h1>
            </div>
          </div>
          {/* {i18nextLng === "ar" && ( */}
            <div
              className="categories"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "30px",
                marginBottom: "30px",
                flexWrap: "wrap",
              }}
            >
              <button
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                  backgroundColor: restaurantData.color,
                  color: "#333",
                  padding: "10px 20px",
                }}
                onClick={fetchAllProducts}
              >
                All
              </button>
              {categories.map((category, index) => (
                <button
                  key={index}
                  style={{
                    margin: "10px",
                    borderRadius: "10px",
                    backgroundColor: restaurantData.color,
                    color: "#333",
                    padding: "10px 20px",
                  }}
                  onClick={() => handleCategoryClick(category._id)}
                >
                  {category.libelle}
                </button>
              ))}
              <div
                style={{
                  margin: "10px",
                  padding: "10px 20px",
                  backgroundColor: "#fff",
                  color: "#333",
                }}
              >
                <Slider
                  range
                  min={0}
                  max={100} // Change this value according to your highest price
                  defaultValue={[0, 100]} // Change this value according to your default price range
                  onChange={handlePriceFilter}
                  value={priceRange}
                  trackStyle={[{ backgroundColor: restaurantData.color }]}
                  handleStyle={[{ borderColor: restaurantData.color }]}
                />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={{ width: "50px", textAlign: "center" }}>
                    ${priceRange[0]}
                  </span>
                  <span style={{ width: "50px", textAlign: "center" }}>
                    ${priceRange[1]}
                  </span>
                </div>
              </div>
            </div>
          {/* )} */}
          {/* {i18nextLng === "fr" && (
            <div
              className="categories"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "30px",
                marginBottom: "30px",
                flexWrap: "wrap",
              }}
            >
              <button
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                  backgroundColor: restaurantData.color,
                  color: "#333",
                  padding: "10px 20px",
                }}
                onClick={fetchAllProducts}
              >
                All
              </button>
              {categories.map((category, index) => (
                <button
                  key={index}
                  style={{
                    margin: "10px",
                    borderRadius: "10px",
                    backgroundColor: restaurantData.color,
                    color: "#333",
                    padding: "10px 20px",
                  }}
                  onClick={() => handleCategoryClick(category._id)}
                >
                  {category.libelle}
                </button>
              ))}
              <div
                style={{
                  margin: "10px",
                  padding: "10px 20px",
                  backgroundColor: "#fff",
                  color: "#333",
                }}
              >
                <Slider
                  range
                  min={0}
                  max={100} // Change this value according to your highest price
                  defaultValue={[0, 100]} // Change this value according to your default price range
                  onChange={handlePriceFilter}
                  value={priceRange}
                  trackStyle={[{ backgroundColor: restaurantData.color }]}
                  handleStyle={[{ borderColor: restaurantData.color }]}
                />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={{ width: "50px", textAlign: "center" }}>
                    ${priceRange[0]}
                  </span>
                  <span style={{ width: "50px", textAlign: "center" }}>
                    ${priceRange[1]}
                  </span>
                </div>
              </div>
            </div>
          )}
          {i18nextLng === "en" && (
            <div
              className="categories"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "30px",
                marginBottom: "30px",
                flexWrap: "wrap",
              }}
            >
              <button
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                  backgroundColor: restaurantData.color,
                  color: "#333",
                  padding: "10px 20px",
                }}
                onClick={fetchAllProducts}
              >
                All
              </button>
              {categories.map((category, index) => (
                <button
                  key={index}
                  style={{
                    margin: "10px",
                    borderRadius: "10px",
                    backgroundColor: restaurantData.color,
                    color: "#333",
                    padding: "10px 20px",
                  }}
                  onClick={() => handleCategoryClick(category._id)}
                >
                  {category.libelle}
                </button>
              ))}
              <div
                style={{
                  margin: "10px",
                  padding: "10px 20px",
                  backgroundColor: "#fff",
                  color: "#333",
                }}
              >
                <Slider
                  range
                  min={0}
                  max={100} // Change this value according to your highest price
                  defaultValue={[0, 100]} // Change this value according to your default price range
                  onChange={handlePriceFilter}
                  value={priceRange}
                  trackStyle={[{ backgroundColor: restaurantData.color }]}
                  handleStyle={[{ borderColor: restaurantData.color }]}
                />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={{ width: "50px", textAlign: "center" }}>
                    ${priceRange[0]}
                  </span>
                  <span style={{ width: "50px", textAlign: "center" }}>
                    ${priceRange[1]}
                  </span>
                </div>
              </div>
            </div>
          )} */}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexFlow: "wrap",
              justifyContent: "center",
            }}
            className=""
          >
            {i18nextLng === "en" && (
              <React.Fragment>
                {props?.translatedProductsToEnglish.map((chunk, index) => (
                  <div
                    key={index}
                    className="dish-row"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {/* {chunk.map((product) => ( */}
                    <div
                      className="dish-item"
                      style={{ flex: "1 1 30%", margin: "10px" }}
                    >
                      <Dish
                        product={chunk}
                        addToCart={() => addToCart(chunk)}
                      />
                    </div>
                    {/* ))} */}
                  </div>
                ))}
              </React.Fragment>
            )}
            {i18nextLng === "fr" && (
              <React.Fragment>
                {props?.translatedProductsToFrench.map((chunk, index) => (
                  <div
                    key={index}
                    className="dish-row"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {/* {chunk.map((product) => ( */}
                    <div
                      className="dish-item"
                      style={{ flex: "1 1 30%", margin: "10px" }}
                    >
                      <Dish
                        product={chunk}
                        addToCart={() => addToCart(chunk)}
                      />
                    </div>
                    {/* ))} */}
                  </div>
                ))}
              </React.Fragment>
            )}
            {i18nextLng === "ar" && (
              <React.Fragment>
                {props?.translatedProductsToArabe.map((chunk, index) => (
                  <div
                    key={index}
                    className="dish-row"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {/* {chunk.map((product) => ( */}
                    <div
                      className="dish-item"
                      style={{
                        display: "flex",
                        flex: "1 1 30%",
                        margin: "10px",
                      }}
                    >
                      <Dish
                        product={chunk}
                        addToCart={() => addToCart(chunk)}
                      />
                    </div>
                    {/* ))} */}
                  </div>
                ))}
              </React.Fragment>
            )}
          </div>
          <div
            className="app__specialMenu flex__center section__padding"
            id="menu"
          >
            <div
              className="app__specialMenu-title"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                marginTop: "50px",
              }}
            >
              <SubHeading title="Menu that fits your palette" />
              <h1 className="headtext__cormorant">Safe Menu</h1>
            </div>
          </div>
          {/* <div className="containers my-5">
  {error ? (
    <p>Error: {error}</p>
  ) : (
    safeProducts.length > 0 ? (
      <div className="product-grid">
        {safeProducts.map((safeProduct) => (
          <div
            key={safeProduct._id}
            className="product-card"
            style={{ filter: restaurantStatus === 'closed' ? 'grayscale(100%)' : 'none' }}
          >
            <div className="card-headers">
              {safeProduct.isOffer && (
                <div className="offer-banner">
                  Today's Offer
                  <span className="discount">{safeProduct.promotion}%</span>
                </div>
              )}
            </div>
            <div className="card-bodys">
              <img src={safeProduct.photo} alt={safeProduct.name} />
              <h5>{safeProduct.name.toUpperCase()}</h5>
              <p>${safeProduct.price}</p>
              <p>{safeProduct.description}</p>
              {safeProduct.ingredients.length > 0 && (
                <table className="ingredients-table">
                  <thead>
                    <tr>
                      <th>Ingredient</th>
                      <th>Calories (kcal)</th>
                      <th>Sugars (g)</th>
                      <th>Carbs (g)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {safeProduct.ingredients.map((ingredient) => (
                      <tr key={ingredient.ingredient}>
                        <td>{ingredient.ingredient}</td>
                        <td>{ingredient.calories !== 'N/A' ? ingredient.calories : 'N/A'}</td>
                        <td>{ingredient.sugars !== 'N/A' ? ingredient.sugars : 'N/A'}</td>
                        <td>{ingredient.carbs !== 'N/A' ? ingredient.carbs : 'N/A'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              <div className="btn-group">
                <button
                  style={{
                    color: 'white',
                    marginTop: '10px',
                    width:'10px',
                    background: restaurantStatus === 'closed' ? 'gray' : 'salmon',
                    cursor: restaurantStatus === 'closed' ? 'not-allowed' : 'pointer',
                  }}
                  onClick={() => {
                    if (restaurantStatus === 'open') {
                      addToCart(safeProduct._id);
                    } else {
                      toast.error('Restaurant is closed');
                    }
                  }}
                  disabled={restaurantStatus === 'closed'}
                >
                  Add to Cart
                </button>
                
              </div>
            </div>
          </div>
        ))}
      </div>
    ) : (
      <p>No safe products available.</p>
    )
  )}
</div> */}

          <div
            className="pagination"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                style={{
                  margin: "0 5px",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  backgroundColor: restaurantData.color,
                }}
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      )}
    </section>
  );
}

export default Dishes;
