
import { Restaurant } from "@material-ui/icons";
import { createSlice } from "@reduxjs/toolkit";
const initialState = { cartData: [], total: 0, tps: 0, tvq: 0, discount: 0, totalQuantity: 0 };

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCart(state, action) {
      state.total = action.payload.total;
      state.cartData = action.payload.cartData;
      state.tps = action.payload.convertedPriceTPS;
      state.tvq = action.payload.convertedPriceTVQ;
    },
    setTps(state, action) {
      state.tps = action.payload;
    },
    setTvq(state, action) {
      state.tvq = action.payload;
    },
    setTotal(state, action) {
      state.total = action.payload;
    },
    applyDiscount(state, action) {
      state.discount = action.payload;
    },
    increaseQuantity(state, action) {
      const product = state.cartData[0]?.productFK.find(item => item._id === action.payload.productId);
      if (product) {
        const index = state.cartData[0]?.productFK.indexOf(product);
        state.cartData[0].quantityProduct[index] += 1;
        state.total += product.price;
      
      }
    },
    decreaseQuantity(state, action) {
      const product = state.cartData[0]?.productFK.find(item => item._id === action.payload.productId);
      if (product) {
        const index = state.cartData[0]?.productFK.indexOf(product);
        if (state.cartData[0].quantityProduct[index] > 1) {
          state.cartData[0].quantityProduct[index] -= 1;
          state.total -= product.price;
          
        }
      }
    },
    clearCart(state) {
      state.cartData = [];
      state.total = 0;
      state.tps = 0;
      state.tvq = 0;
      state.discount = 0;
    },
  }
});


export const cartActions = cartSlice.actions
export default cartSlice