// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReclamationList_notificationsContainer__AArAD {
  padding: 20px;
  background-color: transparent;
  margin-top: 5%;
  width: 50%;
  margin-left: 20%;
}

.ReclamationList_title__3mYni {
  font-size: 24px;
  margin-bottom: 20px;
  display: block;
  align-items: center;
  margin-left: 45%;
}

.ReclamationList_error__Y6-jk {
  color: red;
  margin-bottom: 20px;
}

.ReclamationList_notificationItem__rcHAN {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.ReclamationList_notificationHeader__P5yxb {
  display: flex;
  align-items: center;
}

.ReclamationList_notificationImage__tCNu4 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 15px;
}

.ReclamationList_notificationTitleContainer__SL7mp {
  display: flex;
  flex-direction: column;
}

.ReclamationList_notificationTitle__6qqiF {
  font-size: 18px;
  margin: 0;
  font-weight: bold;
}

.ReclamationList_notificationBody__ONSOn {
  margin-top: 10px;
}

.ReclamationList_notificationType__nxxzf {
  font-size: 16px;
  margin: 0;
  color: #333;
}

.ReclamationList_notificationMessage__rbB4x {
  margin: 10px 0;
  font-size: 14px;
  color: #666;
}

.ReclamationList_notificationDate__Ej9zW {
  font-size: 12px;
  color: #999;
}
`, "",{"version":3,"sources":["webpack://./src/components/Menu/search/ReclamationList.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;EAC7B,cAAc;EACd,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,cAAc;EACd,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,uCAAuC;EACvC,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,SAAS;EACT,WAAW;AACb;;AAEA;EACE,cAAc;EACd,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;EACf,WAAW;AACb","sourcesContent":[".notificationsContainer {\n  padding: 20px;\n  background-color: transparent;\n  margin-top: 5%;\n  width: 50%;\n  margin-left: 20%;\n}\n\n.title {\n  font-size: 24px;\n  margin-bottom: 20px;\n  display: block;\n  align-items: center;\n  margin-left: 45%;\n}\n\n.error {\n  color: red;\n  margin-bottom: 20px;\n}\n\n.notificationItem {\n  display: flex;\n  flex-direction: column;\n  background-color: white;\n  padding: 15px;\n  border-radius: 8px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  margin-bottom: 15px;\n}\n\n.notificationHeader {\n  display: flex;\n  align-items: center;\n}\n\n.notificationImage {\n  width: 100px;\n  height: 100px;\n  border-radius: 50%;\n  margin-right: 15px;\n}\n\n.notificationTitleContainer {\n  display: flex;\n  flex-direction: column;\n}\n\n.notificationTitle {\n  font-size: 18px;\n  margin: 0;\n  font-weight: bold;\n}\n\n.notificationBody {\n  margin-top: 10px;\n}\n\n.notificationType {\n  font-size: 16px;\n  margin: 0;\n  color: #333;\n}\n\n.notificationMessage {\n  margin: 10px 0;\n  font-size: 14px;\n  color: #666;\n}\n\n.notificationDate {\n  font-size: 12px;\n  color: #999;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationsContainer": `ReclamationList_notificationsContainer__AArAD`,
	"title": `ReclamationList_title__3mYni`,
	"error": `ReclamationList_error__Y6-jk`,
	"notificationItem": `ReclamationList_notificationItem__rcHAN`,
	"notificationHeader": `ReclamationList_notificationHeader__P5yxb`,
	"notificationImage": `ReclamationList_notificationImage__tCNu4`,
	"notificationTitleContainer": `ReclamationList_notificationTitleContainer__SL7mp`,
	"notificationTitle": `ReclamationList_notificationTitle__6qqiF`,
	"notificationBody": `ReclamationList_notificationBody__ONSOn`,
	"notificationType": `ReclamationList_notificationType__nxxzf`,
	"notificationMessage": `ReclamationList_notificationMessage__rbB4x`,
	"notificationDate": `ReclamationList_notificationDate__Ej9zW`
};
export default ___CSS_LOADER_EXPORT___;
