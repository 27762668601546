import FindPassword from '../components/Forget password/FindPassword'
import ForgetPassword from '../components/Forget password/ForgetPassword'

const ForgetPasswordPage = () => {
  return (
    <div className='page'>

         <ForgetPassword   />
    </div>
  )
}

export default ForgetPasswordPage