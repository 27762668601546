import React, { useState } from "react";
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import styles from "./PaymentMethod.module.css";
import { useSelector } from "react-redux";
import ReactStars from "react-rating-stars-component";
import { useTranslation } from 'react-i18next';
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import close from "../../assets/xmark-solid.svg";

const PaymentForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showRatingTipPopup, setShowRatingTipPopup] = useState(false);
  const userId = localStorage.getItem("userId");
  const restaurantId = localStorage.getItem("restaurantId");
  const tableNb = useSelector((state) => state.restaurant.tableNb);
  const [selectedAllergy, setSelectedAllergy] = useState("");
  const [otherAllergy, setOtherAllergy] = useState("");
  const [orderId, setOrderId] = useState("");
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [tip, setTip] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showPaymentPopup, setShowPaymentPopup] = useState(true);

  const ratingChanged = (newRating) => {
    setRating(newRating);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      setError("Payment information is incomplete.");
      setLoading(false);
      return;
    }

    try {
      const orderResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/order/addOrderweb/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            restaurantFK: restaurantId,
            tableNb: tableNb,
            allergy: selectedAllergy === "Other" ? otherAllergy : selectedAllergy,
            payMethod: "Card", // Ensure payMethod is set to "Card"
          }),
        }
      );

      const orderData = await orderResponse.json();
      if (!orderResponse.ok) {
        throw new Error(orderData.message || orderData.error);
      }

      const orderId = orderData.data._id;
      setOrderId(orderId);

      const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/stripe/create-payment-intent`, {
        amount: 100, // Adjust amount as needed
      });

      const clientSecret = data.clientSecret;

      const paymentResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            // Include additional billing details if needed
          },
        },
      });

      if (paymentResult.error) {
        setError(paymentResult.error.message);
      } else {
        if (paymentResult.paymentIntent.status === "succeeded") {
          await axios.put(`${process.env.REACT_APP_BACKEND_URL}/order/credit/card/method/paymentweb/${userId}`, {
            paymentIntentId: paymentResult.paymentIntent.id,
            paymentMethod: "Card", // Ensure paymentMethod is set to "Card"
          });

          setShowRatingTipPopup(true);
        }
      }
    } catch (error) {
      setError("Error processing payment. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleFeedbackSubmit = async () => {
    try {
      console.log('Submitting feedback with the following data:');
      console.log('User ID:', userId);
      console.log('Restaurant ID:', restaurantId);
      console.log('Order ID:', orderId);
      console.log('Comment:', comment);
      console.log('Rating:', rating);
      console.log('Tip:', tip);

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/avis/resturant/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          comment,
          note: rating,
          restaurantFK: restaurantId,
          orderFK: orderId,
        }),
      });

      const responseData = await response.json();
      console.log('Response data:', responseData);

      if (!response.ok) {
        throw new Error(responseData.message || responseData.error);
      }

      toast.success('Thanks for your feedback!', {
        style: {
          border: '1px solid #FA8072',
          padding: '16px',
          color: '#FA8072',
        },
        iconTheme: {
          primary: '#FA8072',
          secondary: '#D6C7D4',
        },
      });

      setOrderId(localStorage.getItem("orderId")); // Ensure the order ID is set
      setShowRatingTipPopup(true); // Show the feedback popup
      navigate('/'); 
    } catch (err) {
      console.error('Error submitting feedback:', err);
      toast.error(err.message, {
        style: {
          border: '1px solid #FA8072',
          padding: '16px',
          color: '#FA8072',
        },
        iconTheme: {
          primary: '#FA8072',
          secondary: '#D6C7D4',
        },
      });
    }
  };

  const handleCancel = () => {  
    navigate('/');
  };

  const closeModal = () => {
    setShowPaymentPopup(false);
    setShowRatingTipPopup(false);
  };

  return (
    <div className={styles.paymentForm}>
      <div className={styles.cardContainer}>
        <div className="card rounded-3">
          <div className="card-body p-4">
            <div className="text-center mb-4">
              <h3>Settings</h3>
              <h6>Payment</h6>
            </div>
            <form onSubmit={handleSubmit}>
              <p className="fw-bold mb-4 pb-2">Saved cards:</p>

              <div className="d-flex flex-row align-items-center mb-4 pb-1">
                <img className="img-fluid" src="https://img.icons8.com/color/48/000000/mastercard-logo.png" />
              </div>

              {/* <p className="fw-bold mb-4">Add new card:</p>

              <div className="form-outline mb-4">
                <input
                  type="text"
                  id="formControlLgXsd"
                  className="form-control form-control-lg"
                  value="Anna Doe"
                  readOnly
                />
                <label className="form-label" htmlFor="formControlLgXsd">Cardholder's Name</label>
              </div> */}

              <div className={styles.cardRow}>
                <div className={styles.stripeInputContainer}>
                  <CardNumberElement className={styles.stripeInput} />
                 
                </div>
                <div className={styles.stripeInputContainer}>
                  <CardExpiryElement className={styles.stripeInput} />
                
                </div>
                <div className={styles.stripeInputContainer}>
                  <CardCvcElement className={styles.stripeInput} />
                
                </div>
              </div>

              <button type="submit" className="btn btn-success btn-lg btn-block">
                {loading ? 'Processing...' : 'Add card'}
              </button>
              {error && <div className="alert alert-danger mt-3">{error}</div>}
            </form>
          </div>
        </div>
      </div>

      {showRatingTipPopup && (
        <div className={styles.modalOverlay} onClick={closeModal}>
          <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
            <div className={styles.top}>
              <h4>{t('allergy.rateExperience')}</h4>
              <img src={close} alt="Close" onClick={closeModal} />
            </div>
            <div className={styles.content}>
              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={24}
                activeColor="#ffd700"
                value={rating}
                classNames={styles.reactStars}
              />
              <textarea
                placeholder={t('allergy.leaveComment')}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                className={styles.textArea}
              />
              <h6>{t('allergy.tip')}</h6>
              <div className={styles.tips}>
                {['5$', '10$', '15$'].map((amount) => (
                  <button
                    key={amount}
                    className={tip === amount ? styles.selectedTip : styles.tip}
                    onClick={() => setTip(amount)}
                  >
                    {amount}
                  </button>
                ))}
                <input
                  type="number"
                  placeholder={t('allergy.tipOther')}
                  value={tip}
                  onChange={(e) => setTip(e.target.value)}
                  className={styles.tipInput}
                />
              </div>
            </div>
            <div className={styles.actions}>
              <button className={styles.cancelButton} onClick={handleCancel}>{t('allergy.cancel')}</button>
              <button onClick={handleFeedbackSubmit} className={styles.submitButton}>{t('allergy.submit')}</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentForm;
