import React, { useState } from "react";
import { useLanguage } from "./LanguageContext";
import axios from "axios";
import flagUK from "../assets/flag-uk.png";
import flagFrance from "../assets/flag-france.png";
import flagSpain from "../assets/flag-spain.png";
import flagPortugal from "../assets/flag-portugal.png";
import flagIndia from "../assets/flag-india.png";
import flagChina from "../assets/flag-china.png";
import flagArabic from "../assets/flag-arabic.png";
import flagGermany from "../assets/german.png";

const LanguageSwitcher = (props) => {
  const { language, changeLanguage } = useLanguage();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const styles = {
    langBox: {
      display: "flex",
      position: "relative",
      top: "5px",
      left: "20px",
      marginRight: "20px",
    },
    lang: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      top: "0px",
      left: "0px",
      marginRight: "-50px",
    },
    dropdownMenu: {
      display: isDropdownOpen ? "block" : "none",
      position: "absolute",
      top: "100%",
      left: 0,
      background: "#fff",
      borderRadius: "5px",
      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
      zIndex: 1,
      marginRight: "-20px",
    },
    flagIcon: {
      width: "20px",
      height: "20px",
      verticalAlign: "middle",
      marginRight: "30px",
    },
    flagIconn: {
      width: "40px",
      height: "40px",
      marrginTop: "50px",
    },
    dropdownItem: {
      display: "flex",
      alignItems: "center",
      padding: "8px 12px",
      textDecoration: "none",
      color: "#333",
      left: "200px",
    },
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const i18nextLng = localStorage.getItem("i18nextLng");

  const handleChangeLanguage = (e,lng) => {
    e.preventDefault();
    if (lng !== language) {
      changeLanguage(lng);
    }
    if (lng == "ar") {
      props.fetchToArabe();
      window.location.reload();

      // props.fetchSimilarToArabe();
      // props.fetchToArabeResto();
    } else if (lng == "fr") {
      props.fetchToFrench();
      window.location.reload();

      // props.fetchSimilarToFrench();
      // props.fetchToFrenchResto();
    } else {
      props.fetchToEnglish();
      window.location.reload();

      // props.fetchSimilarToEnglish();
      // props.fetchToEnglishResto()
    }
    setIsDropdownOpen(false);
  };

  return (
    <div className="header_box" style={styles.lang}>
      <div className="lang_box" style={styles.langBox}>
        <a
          href="#"
          title="Language"
          onClick={(e) => {
            e.preventDefault();
            toggleDropdown();
          }}
        >
          <img
            src={getFlagImage(language)}
            style={styles.flagIconn}
            alt="flag"
            className="mr-2"
          />
        </a>

        <div className="dropdown-menu" style={styles.dropdownMenu}>
          {["en", "fr", "es", "pt", "in", "ch", "ar", "al"].map((lng) => (
            <a
              href="#"
              key={lng}
              className="dropdown-item"
              style={styles.dropdownItem}
              onClick={(e) => handleChangeLanguage(e,lng)}
            >
              <img
                src={getFlagImage(lng)}
                style={styles.flagIcon}
                alt="flag"
                className="mr-2"
              />
              {getLanguageName(lng)}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

// Helper functions to get flag image and language name
const getFlagImage = (lng) => {
  switch (lng) {
    case "en":
      return flagUK;
    case "fr":
      return flagFrance;
    case "es":
      return flagSpain;
    case "pt":
      return flagPortugal;
    case "in":
      return flagIndia;
    case "ch":
      return flagChina;
    case "ar":
      return flagArabic;
    case "al":
      return flagGermany;

    default:
      return flagUK;
  }
};

const getLanguageName = (lng) => {
  switch (lng) {
    case "en":
      return "English";
    case "fr":
      return "Français";
    case "es":
      return "Español";
    case "pt":
      return "Português";
    case "in":
      return "भारतीय";
    case "ch":
      return "中文";
    case "ar":
      return "العربية";
    case "al":
      return "Deutsche";
    default:
      return "Unknown";
  }
};

export default LanguageSwitcher;
