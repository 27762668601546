// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* components/Toast.css */
.Toast_toast__UGjDv {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 10000;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Home/Toast.module.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;EAChB","sourcesContent":["/* components/Toast.css */\n.toast {\n    position: fixed;\n    bottom: 20px;\n    right: 20px;\n    background-color: #007bff;\n    color: white;\n    padding: 10px 20px;\n    border-radius: 5px;\n    z-index: 10000;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toast": `Toast_toast__UGjDv`
};
export default ___CSS_LOADER_EXPORT___;
