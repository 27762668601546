import { useState, useEffect } from "react";
import avatar from "../../assets/avatar.png";
import styles from "./Profile.module.css";
import { Toaster, toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import EditProfile from "./EditProfile";
import EditPassword from "./EditPassword";
import AllergiesBadge from "./AllergiesBadge"; // Import the correct component
import AllergiesModal from './AllergiesModal'; // Import the correct component
import Navs from "../Navs/Navs";
import Footer from "../Footer/Footer";
import { AiOutlineUser, AiOutlineLock ,AiOutlineCamera  } from "react-icons/ai";
import { MdLock, MdPhone, MdEmail, MdCake, MdLocationOn, MdPerson , MdEdit, MdNotifications, MdExitToApp } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Profile = () => {
  const [profile, setProfile] = useState({});
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const [showModal, setShowModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [showAllergiesModal, setShowAllergiesModal] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const isGoogleAuth = useSelector((state) => state.auth.isGoogleAuthenticated);
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const { t } = useTranslation();

  const fetchProfile = async () => {
    try {
      if (!userId) {
        throw new Error("User ID not found in localStorage");
      }

      const url = `${process.env.REACT_APP_BACKEND_URL}/user/getById/${userId}`;

      const response = await fetch(url, {
        credentials: "include",
      });

      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message || responseData.error);
      } else {
        const { firstName, lastName, email, role, createdAt, updatedAt, phone, birthday, address, image } = responseData;
        setProfile({ firstName, lastName, email, role, createdAt, updatedAt, phone, birthday, address, image });
        if (image) {
          setImageURL(`${process.env.REACT_APP_BACKEND_URL}/user/getImageByUserId/${userId}`);
        }
      }
    } catch (err) {
      toast.error(err.message, {
        style: { border: "1px solid #FA8072", padding: "16px", color: "#FA8072" },
        iconTheme: { primary: "#FA8072", secondary: "#f88f8f" },
      });
    }
  };

  useEffect(() => {
    if (isAuth || isGoogleAuth) fetchProfile();
  }, [showModal]);

  const handleUploadImage = async (event) => {
    try {
      if (!userId) {
        throw new Error("User ID not found in localStorage");
      }

      const formData = new FormData();
      formData.append("image", event.target.files[0]);

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/user/updateUserImage/${userId}`,
        {
          method: "POST",
          credentials: "include",
          body: formData,
        }
      );
      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || responseData.error);
      } else {
        setImageURL(responseData.imageUrl);
        toast.success("Image updated successfully", {
          style: {
            border: "1px solid #FA8072",
            padding: "16px",
            color: "#FA8072",
          },
          iconTheme: {
            primary: "#FA8072",
            secondary: "#f88f8f",
          },
        });
      }
    } catch (err) {
      toast.error(err.message, {
        style: {
          border: "1px solid #FA8072",
          padding: "16px",
          color: "#FA8072",
        },
        iconTheme: {
          primary: "#FA8072",
          secondary: "#f88f8f",
        },
      });
    }
  };

  const getImage = async () => {
    try {
      if (!userId) {
        throw new Error("User ID not found in localStorage");
      }

      const url = `${process.env.REACT_APP_BACKEND_URL}/user/getImageByUserId/${userId}`;

      const response = await fetch(url, {
        credentials: "include",
      });

      if (!response.ok) {
        if (response.status === 401) {
          navigate('/login');
          return;
        }
        const responseData = await response.json();
        throw new Error(responseData.message || responseData.error);
      } else {
        const blob = await response.blob();
        const imageUrl = URL.createObjectURL(blob);
        setImageURL(imageUrl);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getImage();
  }, []);

  useEffect(() => {
    if (isAuth) getImage();
  }, [handleUploadImage]);

  const formatNumber = (number) => {
    return number < 10 ? `0${number}` : `${number}`;
  };

  return (
    <>
      <Navs />
      <section className={styles.profileSection}>
        <div>
          <Toaster />
        </div>
        {isAuth ? (
          <div className={styles.profileContainer}>
            <div className={styles.profileSidebar}>
              <h4 className={styles.sidebarTitle}>{t('profile.accountProfile')}</h4>
              <div className={styles.sidebarItem}>
                <MdPerson className={styles.icon} />
                <a data-toggle="list">{t('profile.general')}</a>
              </div>
              <div className={styles.sidebarItem} onClick={() => setShowModal(true)}>
                <MdEdit className={styles.icon} />
                <a data-toggle="list">{t('profile.editProfile')}</a>
              </div>
              <div className={styles.sidebarItem} onClick={() => setShowSecondModal(true)}>
                <MdLock className={styles.icon} />
                <a data-toggle="list">{t('profile.changePassword')}</a>
              </div>
              <div className={styles.sidebarItem} onClick={() => setShowAllergiesModal(true)}>
                <MdNotifications className={styles.icon} />
                <a data-toggle="list">{t('profile.notifications')}</a>
              </div>
              <div className={styles.sidebarItem}>
                <MdExitToApp className={styles.icon} />
                <a data-toggle="list" href="#account-connections">{t('profile.logOut')}</a>
              </div>
            </div>
            <div className={styles.profileContent}>
              <div className={styles.avatarContainer}>
                <label htmlFor="avatar">
                  <div className={styles.imageContainer}>
                    <img src={imageURL || avatar} alt="avatar" className={styles.imageURL} />
                    <div className={styles.cameraIconContainer}>
                      <AiOutlineCamera className={styles.cameraIcon} />
                    </div>
                  </div>
                </label>
                <input
                  id="avatar"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleUploadImage}
                />
                <div className={styles.profileName}>
                  {profile.firstName} {profile.lastName}
                </div>
                <div className={styles.profileAddress}>
                  {profile.address}
                </div>
                <div className={styles.allergiesBadge}>
                  <AllergiesBadge userId={userId} />
                </div>
              </div>
              <div className={styles.profileInfo}>
                <div className={styles.infoItem}>
                  <MdPerson className={styles.infoIcon} />
                  <label>{t('profile.firstName')} :</label>
                  <input
                    type="text"
                    className="form-control"
                    value={profile.firstName}
                    readOnly
                  />
                </div>
                <div className={styles.infoItem}>
                  <MdPerson className={styles.infoIcon} />
                  <label>{t('profile.lastName')} :</label>
                  <input
                    type="text"
                    className="form-control"
                    value={profile.lastName}
                    readOnly
                  />
                </div>
                <div className={styles.infoItem}>
                  <MdEmail className={styles.infoIcon} />
                  <label>{t('profile.email')} :</label>
                  <input
                    type="email"
                    className="form-control"
                    value={profile.email}
                    readOnly
                  />
                </div>
                <div className={styles.infoItem}>
                  <MdPhone className={styles.infoIcon} />
                  <label>{t('profile.phoneNumber')}:</label>
                  <input
                    type="tel"
                    className="form-control"
                    value={profile.phone}
                    readOnly
                  />
                </div>
                <div className={styles.infoItem}>
                  <MdCake className={styles.infoIcon} />
                  <label>{t('profile.dateOfBirth')}:</label>
                  <div className={styles.dateFields}>
                    <input
                      type="text"
                      className="form-control"
                      value={formatNumber(new Date(profile.birthday).getDate())}
                      readOnly
                    />
                    <input
                      type="text"
                      className="form-control"
                      value={formatNumber(new Date(profile.birthday).getMonth() + 1)}
                      readOnly
                    />
                    <input
                      type="text"
                      className="form-control"
                      value={new Date(profile.birthday).getFullYear()}
                      readOnly
                    />
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <MdLock className={styles.infoIcon} />
                  <label>{t('profile.memberSince')}:</label>
                  <div className={styles.dateFields}>
                    <input
                      type="text"
                      className="form-control"
                      value={formatNumber(new Date(profile.createdAt).getDate())}
                      readOnly
                    />
                    <input
                      type="text"
                      className="form-control"
                      value={formatNumber(new Date(profile.createdAt).getMonth() + 1)}
                      readOnly
                    />
                    <input
                      type="text"
                      className="form-control"
                      value={new Date(profile.createdAt).getFullYear()}
                      readOnly
                    />
                  </div>
                </div>
                <div className={styles.infoItem}>
                  <MdLocationOn className={styles.infoIcon} />
                  <label>{t('profile.location')}:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={profile.address}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <h1 className={styles.title}>{t('profile.notAuthorized')}</h1>
        )}
        {showModal && (
          <EditProfile closeModal={() => setShowModal(false)} profile={profile} />
        )}
        {showSecondModal && (
          <EditPassword closeModal={() => setShowSecondModal(false)} />
        )}
        {showAllergiesModal && <AllergiesModal open={showAllergiesModal} handleClose={() => setShowAllergiesModal(false)} />}
      </section>
      <Footer />
    </>
  );
};

export default Profile;
