import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import OriginalLogo from "../../assets/logo11.png"; // Ensure the path is correct

// Styles for the PDF
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        padding: 30,
        fontSize: 12,
        fontFamily: 'Helvetica',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    logo: {
        width: 80,
        height: 40,
    },
    section: {
        marginBottom: 20,
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        flexDirection: 'row',
    },
    tableColHeader: {
        width: '33%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        backgroundColor: '#FFE0DD',
        padding: 5,
    },
    tableCol: {
        width: '33%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: 5,
    },
    tableCellHeader: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    tableCell: {
        fontSize: 12,
    },
    total: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    totalText: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
        marginBottom: 20,
        color: '#FA8071',
    },
});

// Generate the PDF document
const OrderInvoice = ({ orderDetails }) => {
    // Ensure totalPrice is a number
    const totalPrice = parseFloat(orderDetails.totalPrice);

    // Calculate TPS and TVQ as numbers
    const tps = parseFloat((totalPrice * orderDetails.restaurantFK.taxeTPS / 100).toFixed(2));
    const tvq = parseFloat((totalPrice * orderDetails.restaurantFK.taxeTVQ / 100).toFixed(2));

    // Calculate total with taxes
    const totalWithTaxes = (totalPrice + tps + tvq).toFixed(2);

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.header}>
                    <Image style={styles.logo} src={OriginalLogo} />
                    <View>
                        <Text>Invoice #: {orderDetails.orderNb}</Text>
                        <Text>Order Date: {new Date(orderDetails.date).toLocaleDateString()}</Text>
                    </View>
                </View>

                <View style={styles.section}>
                    <Text>From:</Text>
                    <Text>{orderDetails.restaurantFK.nameRes}</Text>
                    <Text>{orderDetails.restaurantFK.address}</Text>
                </View>

                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Item</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Quantity</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Price</Text>
                        </View>
                    </View>
                    {orderDetails.cartOrderFK.map((item, cartIndex) =>
                        item.productFK.map((product, index) => (
                            <View style={styles.tableRow} key={index}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{product.name}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{item.quantityProduct[index]}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>${product.price}</Text>
                                </View>
                            </View>
                        ))
                    )}
                </View>
                
                <View style={styles.total}>
                    <Text style={styles.totalText}>Payment Method: {orderDetails.payMethod}</Text>
                </View>
                <View style={styles.total}>
                    <Text style={styles.totalText}>TPS: ${tps}</Text>
                </View>
                <View style={styles.total}>
                    <Text style={styles.totalText}>TVQ: ${tvq}</Text>
                </View>
                <View style={styles.total}>
                    <Text style={styles.totalText}>Total: ${totalWithTaxes}</Text>
                </View>
            </Page>
        </Document>
    );
};

export default OrderInvoice;
