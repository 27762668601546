import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faPaperPlane, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import ReactRecorder from './TimeRecorder'; // Ensure you have this component
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const socket = io('https://socket.themenufy.com');

const Chat = ({ conversationId, onClose, restaurantName, restaurantId }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const currentUserId = localStorage.getItem('userId');
  const [audioBlob, setAudioBlob] = useState(null);
  const [restaurantData, setRestaurantData] = useState(null);
  const [claimStep, setClaimStep] = useState(null);
  const [claimType, setClaimType] = useState('');
  const [claimMessage, setClaimMessage] = useState('');
  const [currentReclamationId, setCurrentReclamationId] = useState(localStorage.getItem('currentReclamationId'));
  const [imgFile, setImgFile] = useState(null);
  const [modalImage, setModalImage] = useState(null);
  const [helpStep, setHelpStep] = useState(null);
  const [helpDetails, setHelpDetails] = useState('');
  const [currentHelpRequestId, setCurrentHelpRequestId] = useState(localStorage.getItem('currentHelpRequestId'));
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (conversationId) {
      socket.emit('joinConversation', conversationId);
      fetchMessages(conversationId);
    }

    socket.on('receiveMessage', (message) => {
      if (message.conversationId === conversationId) {
        setMessages((prevMessages) => [...prevMessages, message]);
        showNotification(message);
      }
    });

    return () => {
      socket.off('receiveMessage');
    };
  }, [conversationId]);

  const fetchMessages = async (conversationId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/messages/messages/${conversationId}`);
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const sendTextMessage = async (text, senderType) => {
    const message = {
      conversationId,
      senderId: currentUserId,
      text,
      senderType,
    };

    try {
      await axios.post('https://socket.themenufy.com/send-message', message);
    } catch (error) {
      console.error('Error sending text message:', error);
     
    }
  };

  const sendTextMessageClaim = async (text, senderType) => {
    const message = {
      conversationId,
      senderId: restaurantId,
      text,
      senderType,
    };

    try {
      await axios.post('https://socket.themenufy.com/send-message', message);
    } catch (error) {
      console.error('Error sending text message:', error);
   
    }
  };

  const sendTextMessageHelp = async (text, senderType) => {
    const message = {
      conversationId,
      senderId: restaurantId,
      text,
      senderType,
    };

    try {
      console.log("Sending help message:", message);
      const response = await axios.post('https://socket.themenufy.com/send-message', message);
      console.log("Response from sending help message:", response);
    } catch (error) {
      console.error('Error sending text message:', error);
    
    }
  };

  const handleSendMessage = async () => {
    if (claimStep) {
      await handleClaimSteps(newMessage);
    } else if (helpStep) {
      await handleHelpSteps(newMessage);
    } else if (newMessage.trim() !== '') {
      await sendTextMessage(newMessage, 'customer');
      setNewMessage('');
    }
  };

  const sendResponseMessage = async (text) => {
    const message = {
      conversationId,
      senderId: restaurantId,
      text,
      senderType: 'restaurant',
      type: 'response'
    };
    await axios.post('https://socket.themenufy.com/send-message', message);
  };

  const sendAudioMessage = async (audioBlob) => {
    const formData = new FormData();
    formData.append('audio', audioBlob, 'audioMessage.wav');
    formData.append('conversationId', conversationId);
    formData.append('senderId', currentUserId);

    try {
      const response = await axios.post(`https://socket.themenufy.com/send-audio-message`, formData);
      socket.emit('sendMessage', response.data.data);
      setMessages((prevMessages) => [...prevMessages, response.data.data]);
    } catch (error) {
      console.error('Error sending audio message:', error);
    }
  };

  const sendImageMessage = (imgFile) => {
    const formData = new FormData();
    formData.append('img', imgFile);
    formData.append('conversationId', conversationId);
    formData.append('senderId', currentUserId);

    fetch('https://socket.themenufy.com/send-img-message', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        socket.emit('sendMessage', data.data);
      })
      .catch((error) => console.error('Error sending image message:', error));
  };

  const handleClaim = async () => {
    try {
      console.log('Creating a new reclamation...');
      const reclamationResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/reclamation/reclamation/add/${currentUserId}`, {
        message: "I want to claim",
        restaurantFK: restaurantId,
      });

      const newReclamation = reclamationResponse.data.data;
      console.log('Reclamation response:', reclamationResponse.data);

      const reclamationId = newReclamation._id;
      if (reclamationId) {
        setCurrentReclamationId(reclamationId);
        localStorage.setItem('currentReclamationId', reclamationId);
        console.log("Reclamation ID:", reclamationId);

        const claimMessage = {
          conversationId,
          senderId: currentUserId,
          text: "I want to claim",
          createdAt: new Date().toISOString(),
          type: 'claim',
          senderType: 'customer',
          reclamationId: reclamationId
        };
        await sendTextMessage("I want to claim", 'customer');
        await sendTextMessageClaim("What type of claim?", 'restaurant');
        setClaimStep('type');
      } else {
        throw new Error('Reclamation ID is undefined');
      }
    } catch (error) {
      console.error('Claim not initiated:', error.message);
      
    
    }
  };

  const handleHelpRequest = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/help/addhelpweb/${currentUserId}`, {
        note: "I need help.",
        type: "general",
        user: currentUserId,
      });

      const newHelpRequest = response.data.data;

      const helpMessage = {
        conversationId,
        senderId: currentUserId,
        text: "I need help.",
        createdAt: new Date().toISOString(),
        type: 'help',
        senderType: 'customer',
      };

      await axios.post('https://socket.themenufy.com/send-message', helpMessage);

      setCurrentHelpRequestId(newHelpRequest._id);
      setHelpStep('details');

      await sendTextMessageHelp("Please provide more details about the help you need.", 'restaurant');
    } catch (error) {
      console.error('Help request not initiated:', error.message);
      
    }
  };

  const handleClaimSteps = async (message) => {
    sendTextMessage(message, 'customer');
    if (claimStep === 'type' && message.trim() !== '') {
      setClaimType(message);
      await sendResponseMessage("Please describe your claim.");
      setClaimStep('describe');
    } else if (claimStep === 'describe' && message.trim() !== '') {
      setClaimMessage(message);
      const claimData = {
        type: claimType,
        message: message,
        reclamationId: currentReclamationId
      };
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/reclamation/reclamation/update/${currentReclamationId}`, claimData);
      await sendResponseMessage("Claim submitted successfully.");

      setClaimStep(null); // End the claim process
      localStorage.removeItem('currentReclamationId');
      setCurrentReclamationId(null);
    }
  };

  const handleHelpSteps = async (message) => {
    try {
      console.log("Step 1: Sending customer message:", message);
      await sendTextMessage(message, 'customer');

      if (helpStep === 'details') {
        console.log("Step 2: Help step is 'details'. Updating help details.");
        setHelpDetails(message);
        setHelpStep(null);

        const helpData = {
          note: message,
        };

        try {
          console.log("Step 3: Sending PUT request to update help request with ID:", currentHelpRequestId);
          const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/help/updateHelp/${currentHelpRequestId}`, helpData);
          console.log("Step 4: Response from updating help request:", response);

          if (response.status === 200) {
            console.log("Step 5: Help request updated successfully.");
            await sendTextMessageHelp("Help request submitted successfully. Please wait a moment for the response. Thank you.", 'restaurant');
           
          } else {
            console.error("Step 6: Failed to update help request. Status:", response.status);
            throw new Error('Failed to update help request');
          }
        } catch (error) {
          console.error('Step 7: Help request not added:', error);
          await sendTextMessageHelp("Help request not added.", 'restaurant');
         
        } finally {
          console.log("Step 8: Resetting current help request ID to null.");
          setCurrentHelpRequestId(null);
        }
      }
    } catch (error) {
      console.error('Step 9: Error handling help steps:', error);
     
    }
  };

  const handleRecordingComplete = (audioBlob) => {
    sendAudioMessage(audioBlob);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    sendImageMessage(file);
  };

  const handleImageClick = (imgSrc) => {
    setModalImage(imgSrc);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  const showNotification = (message) => {
    toast.info(`New message from ${message.senderType === 'restaurant' ? restaurantName : 'You'}: ${message.text}`);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleDeleteMessage = (messageId) => {
    fetch(`https://socket.themenufy.com/delete-message/${messageId}`, {
      method: 'DELETE',
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Message deleted:', data);
        setMessages((prevMessages) => prevMessages.filter((msg) => msg._id !== messageId));
      })
      .catch((error) => console.error('Error deleting message:', error));
  };
  const styles = {
    overlay: {
      position: 'fixed',
      top: 60,
      bottom: 0,
      left: -30,
      width: '100%',
      height: '100%',
      background: 'transparent',
      display: 'flex',
      justifyContent: 'right',
      alignItems: 'center',
    },
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '10%',
      height: '100%',
     
      zIndex: 950,
    },
    modalImage: {
      maxWidth: '1590%',
      maxHeight: '90%',
    },
    customFileUpload: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      color: 'white',
      fontSize: '16px',
      marginRight: '10px',
      marginLeft: '10px',
    },
    customFileUploadIcon: {
      marginRight: '5px',
      fontSize: '24px',
    },
    chatBox: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '500px',
      height:"600px",
      position: 'fixed',
      bottom: 0,
      right: 0,
      backgroundColor: 'white',
      border: '1px solid #ccc',
      borderRadius: '10px',
      padding: '10px',
      zIndex: 1000,
    },
  input: {
      flex: 1,
      border: 'none',
      padding: '10px',
      borderRadius: '15px',
      marginRight: '20px',
      marginLeft: '20px',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      color: 'white',
      '@media (max-width: 768px)': {
          padding: '8px', // Un peu moins de padding
          marginRight: '10px',
          marginLeft: '10px',
      },
      '@media (max-width: 480px)': {
          padding: '6px', // Encore moins de padding pour plus d'espace
      }
  },
  message: {
      padding: '10px',
      borderRadius: '10px',
      backgroundColor: '#FFE0DD',
      position: 'relative',
      maxWidth: '70%',
      display: 'inline-flex',
      alignItems: 'center',
      '@media (max-width: 908px)': {
          padding: '8px',
          maxWidth: '80%', // Messages plus larges sur petits écrans
      },
      '@media (max-width: 980px)': {
          padding: '6px',
          maxWidth: '90%', // Presque pleine largeur
      }
  },
    chatHeader: {
      padding: '10px',
      background: 'linear-gradient(to right, #FA8072, rgba(250, 128, 114, 0.7))',
      color: 'white',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
      animation: 'gradientBackground 3s infinite alternate',
      transition: 'background 0.5s ease',
      textAlign: 'center',
      '@media (max-width: 915)': {
        padding: '5px',
        fontSize: '12px',
      },
    },
    restaurantName: {
      flex: '1',
      textAlign: 'center',
      fontSize: '24px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      margin: '0',
      fontFamily: 'Playfair Display, serif',
      '@media (max-width: 915)': {
        fontSize: '18px',
      },
    },
    restaurantNameContainer: {
      padding: '3px 8px',
      background: 'transparent',
      color: 'black',
      borderRadius: '15px',
      marginBottom: '5px',
      maxWidth: 'fit-content',
      textAlign: 'center',
      fontSize: '12px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      '@media (max-width: 915)': {
        fontSize: '10px',
      },
    },
    chatBody: {
      flex: 1,
      padding: '10px',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
      '@media (max-width: 915)': {
        padding: '5px',
      },
    },
    messageContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      marginBottom: '10px',
      position: 'relative',
      '@media (max-width: 915)': {
        marginBottom: '5px',
      },
    },
    message: {
      padding: '10px',
      borderRadius: '10px',
      backgroundColor: '#FFE0DD',
      position: 'relative',
      maxWidth: '70%',
      display: 'inline-flex',
      alignItems: 'center',
      '@media (max-width: 915px)': {
        padding: '5px',
        maxWidth: '90%',
      },
    },
    received: {
      alignSelf: 'flex-end',
      backgroundColor: '#EDEDEE',
    },
    messageText: {
      marginLeft: '10px',
      color: 'black',
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
      maxWidth: '100%',
      '@media (max-width: 915px)': {
        marginLeft: '5px',
        fontSize: '12px',
      },
    },
    optionButtons: {
      display: 'flex',
      justifyContent: 'space-around',
      marginBottom: '-5px',
      backgroundColor: 'white',
    },
    optionButton: {
      backgroundColor: 'white',
      color: '#FA8072',
      border: 'none',
      padding: '10px 20px',
      borderRadius: '20px',
      border: '2px solid #FA8072', // Added border color
      cursor: 'pointer',
      '@media (max-width: 915px)': {
        padding: '5px 10px',
        fontSize: '12px',
      },
    },
    messageTime: {
      marginTop: '5px',
      fontSize: '10px',
      color: 'black',
      textAlign: 'right',
      '@media (max-width: 915px)': {
        fontSize: '8px',
      },
    },
    chatFooter: {
      padding: '10px',
      background: 'linear-gradient(to right, #FA8072, rgba(250, 128, 114, 0.7))',
      display: 'flex',
      alignItems: 'center',
      borderTop: '1px solid rgba(255, 255, 255, 0.2)',
      animation: 'gradientBackground 3s infinite alternate',
      transition: 'background 0.5s ease',
      '@media (max-width: 915px)': {
        padding: '5px',
      },
    },
    input: {
      flex: 1,
      border: 'none',
      padding: '10px',
      borderRadius: '15px',
      marginRight: '20px',
      marginLeft: '20px',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      color: 'white',
      '@media (max-width: 915px)': {
        padding: '5px',
        marginRight: '10px',
        marginLeft: '10px',
      },
    },
    button: {
      backgroundColor: '#25D366',
      border: 'none',
      padding: '10px 20px',
      borderRadius: '15px',
      color: 'white',
      cursor: 'pointer',
      '@media (max-width: 915px)': {
        padding: '5px 10px',
        fontSize: '12px',
      },
    },
    closeButton: {
      background: 'none',
      border: 'none',
      color: 'white',
      fontSize: '16px',
      cursor: 'pointer',
      '@media (max-width: 915px)': {
        fontSize: '12px',
      },
    },
    actionButtons: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '10px',
      '@media (max-width: 915px)': {
        marginBottom: '5px',
      },
    },
    actionButton: {
      backgroundColor: '#FA8072',
      color: 'white',
      border: 'none',
      padding: '10px 20px',
      borderRadius: '15px',
      cursor: 'pointer',
      marginRight: '10px',
      '@media (max-width: 915px)': {
        padding: '5px 10px',
        marginRight: '5px',
        fontSize: '12px',
      },
    },
    avatar: {
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      marginRight: '10px',
      '@media (max-width: 915px)': {
        width: '20px',
        height: '20px',
        marginRight: '5px',
      },
    },
    iconsend: {
      backgroundColor: 'transparent',
      border: 'none',
      fontSize: '1rem',
      color: 'white',
      cursor: 'pointer',
      '@media (max-width: 915px)': {
        fontSize: '0.8rem',
      },
    },
    iconimage: {
      backgroundColor: 'transparent',
      border: 'none',
      fontSize: '1rem',
      color: 'white',
      cursor: 'pointer',
      marginRight: '10px',
      '@media (max-width: 915px)': {
        fontSize: '0.8rem',
        marginRight: '5px',
      },
    },
    
    deleteIcon: {
      backgroundColor: 'transparent',
      border: 'none',
      fontSize: '1rem',
      color: 'white',
      cursor: 'pointer',
      marginLeft: '10px',
      '@media (max-width: 915px)': {
        fontSize: '0.8rem',
        marginLeft: '5px',
      },
    },
    '@keyframes gradientBackground': {
      '0%': {
        background: 'linear-gradient(to right, #FA8072, rgba(250, 128, 114, 0.7))',
      },
      '100%': {
        background: 'linear-gradient(to right, #FA8072, rgba(250, 128, 114, 0.3))',
      },
    },
    
  };

  return (
    <div style={styles.overlay}>

      <div style={styles.chatBox}>
        <div style={styles.chatHeader}>
          <div style={styles.restaurantName}>{restaurantName}</div>
          <button onClick={onClose} style={styles.closeButton}>X</button>
        </div>
        <div style={styles.chatBody}>
          {messages.map((message, index) => {
            const isSender = message.senderId === currentUserId;
            const isRestaurant = message.senderType === 'restaurant';

            return (
              <React.Fragment key={index}>
                {index === 0 || messages[index - 1].senderId !== message.senderId ? (
                  <div
                    style={{
                      ...styles.messageContainer,
                      alignItems: isSender ? 'flex-start' : 'flex-end',
                    }}
                  >
                    <div
                      style={{
                        ...styles.restaurantNameContainer,
                        ...(isSender ? {} : styles.restaurantNameContainer),
                      }}
                    >
                      <div style={styles.restaurantNameContainer}>
                        {isSender ? 'You' : restaurantName}
                      </div>
                    </div>
                  </div>
                ) : null}

                <div
                  style={{
                    ...styles.messageContainer,
                    alignItems: isSender ? 'flex-start' : 'flex-end',
                  }}
                >
                  <div
                    style={{
                      ...styles.message,
                      ...(isSender ? {} : styles.received),
                      ...(isRestaurant ? { backgroundColor: '#FFD700' } : {}),
                    }}
                  >
                    <div style={styles.messageText}>
                      {message.text ||
                        (message.audioUrl && (
                          <audio controls src={message.audioUrl} />
                        ))}
                    </div>
                    {message.img && (
                      <img
                        src={`${message.img}`}
                        width="200"
                        height="200"
                        onClick={() => handleImageClick(message.img)}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                    {isSender && (
                      <button onClick={() => handleDeleteMessage(message._id)} style={styles.deleteIcon}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    )}
                  </div>
                  <span style={styles.messageTime}>
                    {new Date(message.createdAt).toLocaleTimeString()}
                  </span>
                </div>
              </React.Fragment>
            );
          })}
          <div style={styles.optionButtons}>
            <button style={styles.optionButton} onClick={handleHelpRequest}>I want help</button>
            <button style={styles.optionButton} onClick={handleClaim}>I want to claim</button>
          </div>
        </div>

        <div style={styles.chatFooter}>
          <ReactRecorder
            audioBlobCallback={(blob) => setAudioBlob(blob)}
            onRecordingComplete={handleRecordingComplete}
          />
          <input
            type="file"
            accept="image/*"
            id="image-upload"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <label htmlFor="image-upload" style={styles.customFileUpload}>
            <FontAwesomeIcon icon={faImage} />
          </label>
          <input
            type="text"
            placeholder="Type a message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            style={styles.input}
          />
          <button onClick={() => handleSendMessage()} style={styles.iconsend}>
            <FontAwesomeIcon icon={faPaperPlane} />
          </button>
        </div>
      </div>
      {modalImage && (
        <div style={styles.modal} onClick={closeModal}>
          <img src={modalImage} alt="Zoomed" style={styles.modalImage} />
        </div>
      )}
    </div>
  );
};

export default Chat;
