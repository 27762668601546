// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wishlist-page .wishlist-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.wishlist-page .wishlist-item {
  flex: 0 1 300px;
  margin: 10px;
}

.wishlist-page .card {
  width: 100%;
  max-width: 300px;
  height: 400px; /* Set a fixed height for the cards */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wishlist-page .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wishlist-page .card-body {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wishlist-page .card-body img {
  max-width: 50%;
  max-height: 100px; /* Set a fixed height for the images */
  object-fit: cover;
  margin-bottom: 10px;
}

.wishlist-page .btn-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.wishlist-page .btn-group button,
.wishlist-page .btn-group a {
  flex: 1 1;
  margin: 0 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Menu/search/WishlistPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,aAAa,EAAE,qCAAqC;EACpD,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,iBAAiB,EAAE,sCAAsC;EACzD,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;AAChC;;AAEA;;EAEE,SAAO;EACP,aAAa;AACf","sourcesContent":[".wishlist-page .wishlist-list {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n\n.wishlist-page .wishlist-item {\n  flex: 0 1 300px;\n  margin: 10px;\n}\n\n.wishlist-page .card {\n  width: 100%;\n  max-width: 300px;\n  height: 400px; /* Set a fixed height for the cards */\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n.wishlist-page .card-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.wishlist-page .card-body {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.wishlist-page .card-body img {\n  max-width: 50%;\n  max-height: 100px; /* Set a fixed height for the images */\n  object-fit: cover;\n  margin-bottom: 10px;\n}\n\n.wishlist-page .btn-group {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n}\n\n.wishlist-page .btn-group button,\n.wishlist-page .btn-group a {\n  flex: 1;\n  margin: 0 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
