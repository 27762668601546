import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BsFillPlayCircleFill } from "react-icons/bs";
import SubHeading from "../../Menu/SubHeading";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const About = () => {
  const [restaurantData, setRestaurantData] = useState(null);
  const { restaurantId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchRestaurant = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/restaurant/retrieve/${restaurantId}`);
        setRestaurantData(response.data);
        console.log(response.data); // Debugging: Check the structure of restaurantData
      } catch (error) {
        console.error('Error fetching restaurant details:', error);
        toast.error('Error fetching restaurant details');
      }
    };
    fetchRestaurant();
  }, [restaurantId]);

  if (!restaurantData) {
    return <div>Loading...</div>; // You can customize this loading state
  }

  return (
    <div style={{ margin: "0 auto", maxWidth: "1200px", padding: "20px" }}>
      {restaurantData && (
        <div className="app__specialMenu flex__center section__padding" id="menu">
          <div className="app__specialMenu-title" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <SubHeading title="About Us" />
          </div>
        </div>
      )}

      <div className="about-section-container" style={{ marginTop: "5px", display: 'flex', alignItems: 'center', gap: '20px' }}>
        <div className="about-section-image-container" style={{ flex: '1', position: 'relative' }}>
          <img
            src={restaurantData && restaurantData.images ? restaurantData.images : "white"}
            alt="About Background"
            style={{ width: '100%', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
          />
        </div>

        <div className="about-section-text-container" style={{ flex: '1', padding: '20px', marginLeft: "-20%" }}>
          <h1 className="primary-heading" style={{ fontSize: '36px', marginBottom: '20px', color: '#333', }}>
            {t('aboutsearch.whoAreWe')}
          </h1>
          <div>
          {restaurantData.workes && restaurantData.workes.length > 0 && (
            restaurantData.workes.map((workDay, index) => (
              <div key={index} style={{ marginLeft: "40%" }}>
                <p>
                  {workDay.day}: {workDay.startWorkes} - {workDay.endWorkes}
                  {parseInt(workDay.endWorkes.split(':')[0], 10) > 22 && (
                    <span style={{ color: "red", marginLeft: "10px" }}>
                      (Late Closing)
                    </span>
                  )}
                </p>
              </div>
            ))
          )}
        </div>



          <div style={{ display: 'flex', gap: '20px', marginTop: '20px',marginLeft: "-10%" }}>
            <button
              className="secondary-button"
              style={{
                color: "white",
                backgroundColor: "#FA8072",
                padding: '10px 20px',
                borderRadius: '5px',
                border: 'none',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
              }}>
              {t('aboutsearch.learnMore')}
            </button>
            <button
              className="watch-video-button"
              style={{
                padding: '10px 20px',
                borderRadius: '5px',
                border: '1px solid #FA8072',
                backgroundColor: 'transparent',
                color: '#FA8072',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
              }}>
              <BsFillPlayCircleFill style={{ marginRight: '10px' }} /> {t('aboutsearch.watchVideo')}
            </button>
          </div>
        </div>
      </div>

      <div className="about-section-features" style={{ display: 'flex', justifyContent: 'space-around', marginTop: '60px', padding: '0 20px' }}>
        <div className="feature" style={{ textAlign: 'center' }}>
          <div style={{ fontSize: '48px', color: '#007BFF' }}>☕</div>
          <h3>{t('aboutsearch.versatileMenu')}</h3>
          <p style={{ fontSize: '14px', color: '#666' }}>{t('aboutsearch.versatileMenuDesc')}</p>
        </div>
        <div className="feature" style={{ textAlign: 'center' }}>
          <div style={{ fontSize: '48px', color: '#007BFF' }}>🍽️</div>
          <h3>{t('aboutsearch.exceptionalService')}</h3>
          <p style={{ fontSize: '14px', color: '#666' }}>{t('aboutsearch.exceptionalServiceDesc')}</p>
        </div>
      </div>
    </div>
  );
};

export default About;
