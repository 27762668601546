




function Carts() {

    return (
        <div>
            <h1>Cart</h1>
        </div>
    );
}
export default Carts;