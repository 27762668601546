import React from "react";
import MenuRest from "../components/Menu/MenuRest";

const MenuRestPage = () => {
  return (
    <div>
      <MenuRest></MenuRest>
    </div>
  );
};

export default MenuRestPage;
