import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { faHeart as solidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as regularHeart } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import './Dish.css'; 
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useSelector, useDispatch } from 'react-redux';
import { wishlistActions } from '../../../store/wishlistSlice';
import { useTranslation } from 'react-i18next';

function Dish({ product }) {
  const [dish, setDish] = useState(product);
  const [quantity, setQuantity] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [cartItems, setCartItems] = useState([]);
  const [restaurantStatus, setRestaurantStatus] = useState('open');
  const restaurantId = useSelector(state => state.restaurant.restaurantId);
  const tableNb = useSelector(state => state.restaurant.tableNb);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const userId = useSelector(state => state.auth.userId);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const storedRestaurantId = localStorage.getItem('restaurantId');
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/restaurant/retrieve/${storedRestaurantId}`)
      .then(response => {
        setRestaurantStatus(response.data.status);
        console.log('Restaurant status:', restaurantStatus);
      })
     
      .catch(error => console.error('Error fetching restaurant status:', error));
  }, [storedRestaurantId]);
  

  const handleFavoriteClick = async () => {
    if (!isAuthenticated) {
      toast.error("Please log in to update your wishlist.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/product/users/${userId}/wishlist/${dish._id}`,
        { isFavorited: !dish.isFavorited },
        { withCredentials: true }
      );

      if (response.status === 200) {
        const updatedIsFavorited = !dish.isFavorited;
        setDish(prevDish => ({ ...prevDish, isFavorited: updatedIsFavorited }));
        dispatch(wishlistActions.toggleWishlist({ ...dish, isFavorited: updatedIsFavorited }));
        toast.success(updatedIsFavorited ? "Added to wishlist!" : "Removed from wishlist!");
      } else {
        throw new Error('Failed to update wishlist');
      }
    } catch (error) {
      console.error('Error updating wishlist:', error);
      toast.error("Error updating wishlist.");
    }
  };

  const addToCart = async () => {
    if (!dish) {
      toast.error('Dish details not available');
      return;
    }

    const userId = localStorage.getItem("userId");
    const userPass = userId || "000000000000000000000000";
    const storedRestaurantId = localStorage.getItem('restaurantId');

    if (!storedRestaurantId) {
      console.error('Restaurant ID is missing');
      toast.error('Restaurant ID is missing');
      return;
    }

    console.log('Adding product to cart:', dish); // Log the product being added to the cart

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/cart/addtocartweb/${userPass}`,
        {
          productFK: dish._id,
          ingredientFK: [],
          itemsFK: [],
          tableNb: tableNb,
          restaurantFK: storedRestaurantId,
        },
        { withCredentials: true }
      );

      if (response.status === 200) {
        toast.success("Product added to cart!");

        // Log the entire response data to verify both CartTrash and CartOrder
        console.log('Response data:', response.data);

        const { latestUserCartTrash, latestUserCartOrder, newCartItemOrder, newCartTrash } = response.data;

        if (latestUserCartTrash) {
          console.log('Product added to CartTrash:', latestUserCartTrash);
        }
        if (latestUserCartOrder) {
          console.log('Product added to CartOrder:', latestUserCartOrder);
        }
        if (newCartItemOrder) {
          console.log('New CartOrder created:', newCartItemOrder);
        }
        if (newCartTrash) {
          console.log('New CartTrash created:', newCartTrash);
        }

        const updatedCartItems = [...cartItems];
        const existingItemIndex = updatedCartItems.findIndex(item => item._id === dish._id);
        if (existingItemIndex !== -1) {
          updatedCartItems[existingItemIndex].quantity += 1;
        } else {
          updatedCartItems.push({ ...dish, quantity: 1 });
        }
        setCartItems(updatedCartItems);

        const cartOrderId = response.data.latestUserCartOrder ? response.data.latestUserCartOrder._id : null;
        if (cartOrderId) {
          localStorage.setItem('cartOrderId', cartOrderId); // Store the CartOrder ID in localStorage
        }
      } else {
        throw new Error('Failed to add product to cart');
      }
    } catch (error) {
      console.error('Error adding product to cart:', error);
      toast.error(error.message || "An error occurred while adding product to cart");
    }
  };

  return (
    <div className="containers my-5">
      {dish && (
        <div className="cards" style={{ filter: restaurantStatus === 'closed' ? 'grayscale(100%)' : 'none' }}>
          <div className="card-headers bg-transparent">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <FontAwesomeIcon
                icon={dish.isFavorited ? solidHeart : regularHeart}
                onClick={handleFavoriteClick}
                style={{ color: dish.isFavorited ? 'red' : 'gray', cursor: 'pointer' }}
              />
              <span>{t('dish.todaysOffer')}</span>
              {dish.promotion > 0 && (
                <span className="text-danger">{dish.promotion}%</span>
              )}
            </div>
          </div>
          <div className="card-body">
            <img style={{borderRadius : 25}} src={`${process.env.REACT_APP_BACKEND_URL}/uploads/product/`+dish.photo} alt={dish.name} className="" />
            <p className='mt-2'>{dish.name.toUpperCase()}</p>
            <span style={{fontSize:24,color:"#DD5746", fontWeight:'bold'}}>${dish.price}</span>
            {/* <p>{t('dish.description')}: {dish.description}</p> */}
            <div className="btn-group w-100">
              <button 
                style={{ color: 'white', background: restaurantStatus === 'closed' ? 'gray' : 'salmon', cursor: restaurantStatus === 'closed' ? 'not-allowed' : 'pointer' }}
                onClick={restaurantStatus === 'open' ? addToCart : () => toast.error('Restaurant is closed')}
                disabled={restaurantStatus.status === 'closed'}
              >
                {t('dish.addToCart')}
              </button>
              <Link to={`/dish-details/${dish._id}`} className="btn btn-link" style={{ color: 'salmon' }}>{t('dish.details')}</Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dish;
