import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, FormControl, InputLabel, Select, MenuItem, Button, TextField } from '@mui/material';
import axios from 'axios';

const AllergiesModal = ({ open, handleClose, refreshAllergies }) => {
  const [allergiesList] = useState([
    "No allergy", "Peanuts", "Tree Nuts","cheese", "Milk (Dairy)", "Gluten", "Lettuce",
    "Diabetes", "Soy", "Corn", "Shellfish", "Eggs", "Fish", "Shellfish",
    "Soy", "Wheat (Gluten)", "Sesame Seeds", "Mustard", "Sulphites", "Celery",
    "Lupin", "Corn", "Meat", "Fruits", "Vegetables"
  ]);
  const [selectedAllergy, setSelectedAllergy] = useState('');
  const [customAllergy, setCustomAllergy] = useState('');
  const [showCustomAllergyInput, setShowCustomAllergyInput] = useState(false);
  const userId = localStorage.getItem('userId');

  const handleChange = (event) => {
    setSelectedAllergy(event.target.value);
    setShowCustomAllergyInput(event.target.value === 'Other'); // Show input field if "Other" is selected
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!userId) {
      console.error('No user ID found');
      return;
    }

    const allergyToSend = selectedAllergy === 'Other' ? customAllergy : selectedAllergy;
    if (!allergyToSend) {
      console.error('Allergy must not be empty');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/user/${userId}/allergy`, {
        allergies: allergyToSend
      });

      console.log('Response data:', response.data);

      // Update the allergies list in the parent component
      if (refreshAllergies) {
        refreshAllergies();
      }

      handleClose(); // Close the dialog on success
    } catch (error) {
      console.error('Error adding allergy:', error.response ? error.response.data : error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add an Allergy</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="allergy-select-label">Allergy</InputLabel>
            <Select
              labelId="allergy-select-label"
              id="allergy-select"
              value={selectedAllergy}
              onChange={handleChange}
              displayEmpty
              renderValue={selectedAllergy !== '' ? undefined : () => <div style={{ color: '#aaa' }}>Select an allergy</div>}
            >
              {allergiesList.map((allergy) => (
                <MenuItem key={allergy} value={allergy}>{allergy}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {showCustomAllergyInput && (
            <TextField
              fullWidth
              margin="normal"
              label="Specify your allergy"
              value={customAllergy}
              onChange={(e) => setCustomAllergy(e.target.value)}
              placeholder="Enter your allergy"
            />
          )}
          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor: 'salmon',
              color: 'white',
              marginTop: 20,
            }}
          >
            Add Allergy
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AllergiesModal;
