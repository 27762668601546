// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../fonts/bodoni-svtytwo-sc-itc-tt-book.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'BodoniSVTTwoSCITCBook';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  h1 {
    font-family: 'BodoniSVTTwoSCITCBook', sans-serif;}`, "",{"version":3,"sources":["webpack://./src/components/Home/Order.module.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,+DAA4E;IAC5E,mBAAmB;IACnB,kBAAkB;EACpB;EACA;IACE,gDAAgD,CAAC","sourcesContent":["@font-face {\n    font-family: 'BodoniSVTTwoSCITCBook';\n    src: url('../../fonts/bodoni-svtytwo-sc-itc-tt-book.ttf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n  }\n  h1 {\n    font-family: 'BodoniSVTTwoSCITCBook', sans-serif;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
