// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Saveurs_button-30__2UvVN {
    align-items: center;
    appearance: none;
    background-color: #FA8072;
    border-radius: 4px;
    border : 1px solid #FA8072;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    font-family: "Amsterdam Four";
    height: 40px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    font-size: 16px;
    color: white;
    border-radius: 25px;
    width: 150px;
  }
  .Saveurs_responsive-image__jjHUB {
    max-width: 100%; /* Assurez-vous que l'image ne dépasse pas la largeur du conteneur parent */
    height: auto; /* Permettez à la hauteur de l'image de s'ajuster automatiquement pour maintenir le ratio original */
    border-radius: 15px; /* Ajoutez la bordure arrondie */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Home/Saveurs.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,0BAA0B;IAC1B,sBAAsB;IACtB,eAAe;IACf,oBAAoB;IACpB,6BAA6B;IAC7B,YAAY;IACZ,uBAAuB;IACvB,cAAc;IACd,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,qBAAqB;IACrB,0CAA0C;IAC1C,iBAAiB;IACjB,yBAAyB;IACzB,0BAA0B;IAC1B,mBAAmB;IACnB,iCAAiC;IACjC,eAAe;IACf,YAAY;IACZ,mBAAmB;IACnB,YAAY;EACd;EACA;IACE,eAAe,EAAE,2EAA2E;IAC5F,YAAY,EAAE,oGAAoG;IAClH,mBAAmB,EAAE,gCAAgC;EACvD","sourcesContent":[".button-30 {\n    align-items: center;\n    appearance: none;\n    background-color: #FA8072;\n    border-radius: 4px;\n    border : 1px solid #FA8072;\n    box-sizing: border-box;\n    cursor: pointer;\n    display: inline-flex;\n    font-family: \"Amsterdam Four\";\n    height: 40px;\n    justify-content: center;\n    line-height: 1;\n    list-style: none;\n    overflow: hidden;\n    padding-left: 16px;\n    padding-right: 16px;\n    position: relative;\n    text-align: left;\n    text-decoration: none;\n    transition: box-shadow .15s,transform .15s;\n    user-select: none;\n    -webkit-user-select: none;\n    touch-action: manipulation;\n    white-space: nowrap;\n    will-change: box-shadow,transform;\n    font-size: 16px;\n    color: white;\n    border-radius: 25px;\n    width: 150px;\n  }\n  .responsive-image {\n    max-width: 100%; /* Assurez-vous que l'image ne dépasse pas la largeur du conteneur parent */\n    height: auto; /* Permettez à la hauteur de l'image de s'ajuster automatiquement pour maintenir le ratio original */\n    border-radius: 15px; /* Ajoutez la bordure arrondie */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button-30": `Saveurs_button-30__2UvVN`,
	"responsive-image": `Saveurs_responsive-image__jjHUB`
};
export default ___CSS_LOADER_EXPORT___;
