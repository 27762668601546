import React, { Fragment, useState, useRef ,useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "./ForgetPassword.module.css";
import brand4 from "../../assets/brand4.png";
import brand5 from "../../assets/brand5.png";
import brand6 from "../../assets/brand6.png";
import { Toaster, toast } from "react-hot-toast";
import pass from "../../assets/lock-solid.svg";
import check from "../../assets/check-solid.svg";
import "./ForgetPassword.module.css";
import { AiOutlineHome, AiOutlineLock } from "react-icons/ai";
import Logo from "../../assets/logo5.png";
import Copyright from "../Footer/Copyright";
import "../ActivateAccount/style.css";
import email from "../../assets/envelope-solid.svg";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../LanguageSwitcher";

const ForgetPassword = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoaded1, setIsLoaded1] = useState(false);
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState(["", "", "", ""]);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // Unique déclaration de la variable
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const { t } = useTranslation();

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    let newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
  
    if (value.length === 1 && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
    updatePasswordStrength(value); // Appel correct de la fonction updatePasswordStrength
  };
  
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsButtonDisabled(!isValidEmail(newEmail));
    setIsLoaded(false);
  };

  const handleEmailSubmission = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/auth/forgotPwd`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
          }),
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        setIsLoaded(true);
        throw new Error(responseData.message || responseData.error);
      } else {
        const toast1 = toast.success(responseData.message, {
          style: {
            border: "1px solid #FA8072",
            padding: "16px",
            color: "#FA8072",
          },
          iconTheme: {
            primary: "#FA8072",
            secondary: "#f88f8f",
          },
        });
        setIsLoaded(true);
        setTimeout(() => {
          toast.remove(toast1);
          setStep(2);
        }, 1000);
      }
    } catch (err) {
      toast.error(err.message, {
        style: {
          border: "1px solid #FA8072",
          padding: "16px",
          color: "#FA8072",
        },
        iconTheme: {
          primary: "#FA8072",
          secondary: "#f88f8f",
        },
      });
    }
  };

  const verifyCode = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/auth/verifCode`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            activationCodeForgotPass: code[0] + code[1] + code[2] + code[3],
          }),
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message || responseData.error);
      } else {
        setIsLoaded1(true);

        const toast2 = toast.success(responseData.message, {
          style: {
            border: "1px solid #FA8072",
            padding: "16px",
            color: "#FA8072",
          },
          iconTheme: {
            primary: "#FA8072",
            secondary: "#f88f8f",
          },
        });
        setTimeout(() => {
          toast.remove(toast2);
          setStep(3);
        }, 1000);
      }
    } catch (err) {
      toast.error(err.message, {
        style: {
          border: "1px solid #FA8072",
          padding: "16px",
          color: "#FA8072",
        },
        iconTheme: {
          primary: "#FA8072",
          secondary: "#f88f8f",
        },
      });
    }
  };

  const resendCode = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/auth/resendForgotCode`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({}),
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message || responseData.error);
      } else {
        toast.success(responseData.message, {
          style: {
            border: "1px solid #FA8072",
            padding: "16px",
            color: "#FA8072",
          },
          iconTheme: {
            primary: "#FA8072",
            secondary: "#f88f8f",
          },
        });
      }
    } catch (err) {
      toast.error(err.message, {
        style: {
          border: "1px solid #FA8072",
          padding: "16px",
          color: "#FA8072",
        },
        iconTheme: {
          primary: "#FA8072",
          secondary: "#f88f8f",
        },
      });
    }
  };

  const handleNewPassword = async () => {
    setIsSubmitting(true);
    try {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@#])(?=.{6,})/;
      // Vérifier si le mot de passe respecte les critères requis
      if (!passwordRegex.test(newPassword)) {
        throw new Error(
          "Le mot de passe doit contenir au moins une minuscule, une majuscule, un caractère '@' ou '#' et avoir une longueur minimale de 6 caractères"
        );
      }

      // Vérifier si les deux mots de passe correspondent
      if (newPassword !== confirmNewPassword) {
        throw new Error("Les mots de passe ne correspondent pas");
      }

      // Votre logique de réinitialisation du mot de passe ici
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/resetPwd`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          password: newPassword,
          confirmPassword: confirmNewPassword,
        }),
      });
      const responseData = await response.json();
      if (!response.ok) {
        setIsLoaded(false);
        throw new Error(responseData.message || responseData.error);
      } else {
        const toast3 = toast.success(responseData.message, {
          style: {
            border: "1px solid #FA8072",
            padding: "16px",
            color: "#FA8072",
          },
          iconTheme: {
            primary: "#FA8072",
            secondary: "#f88f8f",
          },
        });
       
        setTimeout(() => {
          toast.remove(toast3);
          navigate("/login");
        }, 1000);
      }
    } catch (err) {
      toast.error(err.message, {
        style: {
          border: "1px solid #FA8072",
          padding: "16px",
          color: "#FA8072",
        },
        iconTheme: {
          primary: "#FA8072",
          secondary: "#f88f8f",
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  const getPasswordStrengthColor = () => {
    switch (passwordStrength) {
      case 0:
      case 1:
        return "red";
      case 2:
      case 3:
        return "orange";
      case 4:
      case 5:
        return "green";
      default:
        return "red";
    }
  };

  const barBackgroundColor = passwordStrength <= 1 ? "red" : "white";

  const updatePasswordStrength = (password) => {
    let strength = 0;

    if (password.length >= 6) strength += 1;

    if (/[a-z]/.test(password)) strength += 1;

    if (/[A-Z]/.test(password)) strength += 1;

    if (/[@#]/.test(password)) strength += 1;

    setPasswordStrength(strength);
  };
  const isCodeFilled = () => {
    return code.every((value) => value !== ""); // Vérifie si tous les champs ne sont pas vides
  };
  useEffect(() => {
    if (isCodeFilled()) {
      verifyCode(); 
    }
  }, [code]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsButtonDisabled(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [isButtonDisabled]);

 return (
    <section className={`${styles.paper} d-flex flex-column align-items-center justify-content-center`}>
      {step === 1 && (
        <section className={`${styles.wrapper} bg-light`}>
          <div>
            <Toaster gutter={20} />
          </div>
          <div className="d-flex justify-content-between align-items-start">
            <a
              title={t('forgetPassword.goHome')}
              style={{ color: "#f88f8f", fontSize: 24 }}
              href="/"
            >
              <AiOutlineHome />

            
            </a>
          <a style={{marginTop:"-15px", marginRight:"400px"}}>
            <LanguageSwitcher />
            </a>
          </div>
          <div className="text-center">
            <img
              src={Logo}
              width={100}
              height={100}
              alt="Logo"
              style={{ borderRadius: '50%', marginTop: "-18px" }}
              className="mb-4"
            />
            <h5>{t('forgetPassword.title')}</h5>
            <p className="mb-2">{t('forgetPassword.enterEmail')}</p>
          </div>
          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleEmailSubmission();
            }}
            className="text-center"
          >
            <div className="mb-3">
              <label htmlFor="email" className="form-label">{t('forgetPassword.email')}</label>
              <input
                type="email"
                className="form-control"
                style={{ height: 50, border: "1px solid #f88f8f", width: "100%" }}
                id="email"
                name="email"
                placeholder={t('forgetPassword.email')}
                value={email}
                onChange={handleEmailChange}
              />
              <br />
              <button
                style={{ borderRadius: 100, height: 50 }}
                type="submit"
                className="btn btn-primary w-100 rounded-pill"
                disabled={isButtonDisabled || isLoaded}
              >
                {!isLoaded ? (
                  <React.Fragment>{t('forgetPassword.submit')}</React.Fragment>
                ) : (
                  <div className="text-white d-flex justify-content-center align-items-center">
                    <div className="donut mx-2"></div>
                    {t('forgetPassword.loading')}
                  </div>
                )}
              </button>
            </div>
          </form>
          <div className="pass-link mt-3 d-flex justify-content-center">
            <a href="/login" className="text-dark">
              <small>{t('forgetPassword.backToLogin')}</small>
            </a>
          </div>
        </section>
      )}
      {step === 2 && (
        <section className="forget mt-4">
          <div>
            <Toaster gutter={20} />
          </div>
          <div className="container bg-light my-7">
            <div className="d-flex justify-content-between align-items-start">
              <a
                title={t('forgetPassword.goHome')}
                style={{ color: "#FA8072", fontSize: 24 }}
                href="/"
              >
                <AiOutlineHome />
               
              </a>
              <a style={{marginTop:"-15px", marginRight:"400px"}}>
            <LanguageSwitcher />
            </a>
            </div>
            <div className="text-center">
              <img
                src={Logo}
                width={100}
                height={100}
                alt="Logo"
                style={{ borderRadius: '50%', marginTop: "-18px" }}
                className="mb-4"
              />
              <p className="mb-5">{t('forgetPassword.validationCode')}</p>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                verifyCode();
              }}
              className="text-center"
            >
              <div className="mb-3">
                <div className="d-flex justify-content-center">
                  {code.map((value, index) => (
                    <input
                      key={index}
                      className={`${styles.codeInput} code`}
                      placeholder="0"
                      type="text"
                      pattern="^[0-9]$"
                      maxLength="1"
                      required
                      value={value}
                      ref={inputRefs[index]}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  ))}
                </div>
                {isCodeFilled() && !isLoaded1 && (
                  <div className="text-center">
                    <div className={styles.notification}>
                      {t('forgetPassword.verifyInProgress')}
                    </div>
                  </div>
                )}
              </div>
              <button
                style={{ borderRadius: 100, height: 50 }}
                type="submit"
                onClick={resendCode}
                className={`btn mt-4 rounded-pill ${styles.resend}`}
                disabled={isButtonDisabled}
              >
                {t('forgetPassword.resendCode')}
              </button>
            </form>
          </div>
        </section>
      )}
      {step === 3 && (
        <section className="forget mt-4">
          <div>
            <Toaster />
          </div>
          <section className={`${styles.wrapper} d-flex flex-column align-items-center justify-content-center bg-light`}>
            <div className="d-flex justify-content-between align-items-start">
              <a
                title="Revenir en accueil"
                style={{ color: "#FA8072", fontSize: 24 }}
                href="/"
              >
                <AiOutlineHome />
              </a>
              <a style={{marginTop:"-15px", marginRight:"400px"}}>
            <LanguageSwitcher />
            </a>
            </div>
            <div className="text-center">
              <img
                src={Logo}
                width={100}
                height={100}
                alt="Logo"
                style={{ borderRadius: '50%', marginTop: "-18px" }}
                className="mb-4"
              />
              <p className="mb-2">{t('forgetPassword.changePassword')}</p>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleNewPassword();
              }}
              className="text-center"
            >
              <div className="mb-3">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  placeholder={t('forgetPassword.newPassword')}
                  style={{ height: 50, border: "1px solid #f88f8f", width: "100%" }}
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    updatePasswordStrength(e.target.value);
                  }}
                />
                <input
                  type="password"
                  className="form-control mt-3"
                  id="confirmpassword"
                  name="confirmpassword"
                  placeholder={t('forgetPassword.confirmNewPassword')}
                  style={{ height: 50, border: "1px solid #f88f8f", width: "100%" }}
                  value={confirmNewPassword}
                  onChange={(e) => {
                    setConfirmNewPassword(e.target.value);
                    updatePasswordStrength(e.target.value);
                  }}
                />
                <button
                  style={{ borderRadius: 100, height: 50, marginTop: "10px" }}
                  type="submit"
                  className="btn btn-primary mt-3 rounded-pill w-100"
                  disabled={isSubmitting}
                >
                  {t('forgetPassword.submitNewPassword')}
                </button>
              </div>
            </form>
          </section>
        </section>
      )}
      <div className={styles.footer}>
        <Copyright />
      </div>
    </section>
  );
};

export default ForgetPassword;