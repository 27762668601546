import ActivateAccount from "../components/ActivateAccount/ActivateAccount"

const ActivatePage = () => {
  return (
    <div className='page'>
      <ActivateAccount />
    </div>
  )
}

export default ActivatePage