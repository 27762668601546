import React, { useState, useEffect } from 'react';

const SafeProductsList = ({ userId, menuId }) => {
  const [safeProducts, setSafeProducts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSafeProducts = async () => {
      try {
        console.log("Fetching safe products with userId:", userId, "and menuId:", menuId);
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URLLL}/recommend/${userId}/${menuId}`);

        if (!response.ok) {
          throw new Error(`Failed to fetch safe products: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        console.log("Data received:", data);

        if (data.error) {
          console.log("API Error:", data.error);
          setError(data.error);
        } else if (!data.safe_products || data.safe_products.length === 0) {
          console.log("No safe products found.");
          setSafeProducts([]);
        } else {
          setSafeProducts(data.safe_products);
        }
      } catch (err) {
        console.error("Fetch error:", err.message);
        setError(`Fetch error: ${err.message}`);
      }
    };

    if (userId && menuId) {
      fetchSafeProducts();
    } else {
      console.warn("UserId or menuId is missing. Cannot fetch safe products.");
    }
  }, [userId, menuId]);

  return (
    <div>
      <h2>Safe Products</h2>
      {error && <p>Error: {error}</p>}
      <ul>
        {safeProducts.length > 0 ? (
          safeProducts.map((product) => (
            <li key={product._id}>
              <h3>{product.name}</h3>
              <img src={product.photo} alt={product.name} style={{ width: '150px', height: 'auto' }} />
              <p>{product.description}</p>
              <p><strong>Price:</strong> ${product.price}</p>
              {product.promotion > 0 && (
                <p><strong>Promotion:</strong> {product.promotion}% off</p>
              )}
            </li>
          ))
        ) : (
          !error && <p>No safe products found.</p>
        )}
      </ul>
    </div>
  );
};

export default SafeProductsList;
