import React, { useState, useRef, useEffect } from "react";
import styles from "./Cart.module.css";
import close from "../../assets/xmark-solid.svg";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import creditImage from "../../assets/credit-card.png";
import CashImage from "../../assets/money.png";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import ReactStars from "react-rating-stars-component";
import { useTranslation } from 'react-i18next';
import PaymentForm from "./PaymentForm";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PayPalForm from "./PayPalForm";
import PayPalImage from "../../assets/paypal.png";
import axios from "axios";

const tableNb = localStorage.getItem('tableNb') || 0;

const Allergy = (props) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const cartData = useSelector((state) => state.cart.cartData);
  const stripePromise = loadStripe('pk_test_51NAIgvEnFoGfPHF6Qgi2OAX8YZuRcvk6Vq93z1RsJ4ZegzWnJ8j4TIWNAv1PiCs6XlyebE23WZg1OvEo45riZRwX00doxqQtcx');
  const creditRef = useRef(null);
  const cashRef = useRef(null);
  const paypalRef = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const allergies = ["No allergy", "Peanuts",
    "Tree Nuts",
    "Milk (Dairy)",
    "Eggs",
    "Fish",
    "Shellfish",
    "Soy",
    "Wheat (Gluten)",
    "Sesame Seeds",
    "Mustard",
    "Sulphites",
    "Celery",
    "Lupin",
    "Corn",
    "Meat",
    "Fruits",
    "Vegetables"];
  const [selectedAllergy, setSelectedAllergy] = useState("No allergy");
  const [otherAllergy, setOtherAllergy] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [showPaymentPopup, setShowPaymentPopup] = useState(true);
  const [showRatingTipPopup, setShowRatingTipPopup] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [tip, setTip] = useState('');
  const userId = localStorage.getItem("userId");
  let restaurantId = localStorage.getItem("restaurantId");
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart'));
    if (storedCart) {
      const totalAmount = storedCart.reduce((total, item) => total + item.price, 0);
      setAmount(totalAmount);
    }
  }, []);

  const handleChange = (e) => {
    setSelectedAllergy(e.target.value);
  };

  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method);
    if (method === "Credit card" || method === "PayPal") {
      setShowPaymentPopup(false);
    }
  };

  const ratingChanged = (newRating) => {
    setRating(newRating);
  };

  const closeModal = () => {
    setShowPaymentPopup(false);
    setShowRatingTipPopup(false);
  };

  const handleSubmit = async () => {
    try {
      if (!isAuthenticated) {
        toast.error("You must be logged in to place an order.", {
          style: {
            border: "1px solid #FA8072",
            padding: "16px",
            color: "#FA8072",
          },
          iconTheme: {
            primary: "#FA8072",
            secondary: "#D6C7D4",
          },
        });
        navigate("/login");
        return;
      }

      if (!userId) {
        toast.error("User ID is not found. Please log in again.", {
          style: {
            border: "1px solid #FA8072",
            padding: "16px",
            color: "#FA8072",
          },
          iconTheme: {
            primary: "#FA8072",
            secondary: "#D6C7D4",
          },
        });
        navigate("/login");
        return;
      }

      if (!restaurantId && cartData.length > 0 && cartData[0].restaurantFK) {
        restaurantId = cartData[0].restaurantFK._id;
      }

      if (!restaurantId) {
        toast.error("Restaurant ID is not found. Please select a restaurant.", {
          style: {
            border: "1px solid #FA8072",
            padding: "16px",
            color: "#FA8072",
          },
          iconTheme: {
            primary: "#FA8072",
            secondary: "#D6C7D4",
          },
        });
        return;
      }

      if (paymentMethod === "") {
        toast.error("Pick a payment method", {
          style: {
            border: "1px solid #FA8072",
            padding: "16px",
            color: "#FA8072",
          },
          iconTheme: {
            primary: "#FA8072",
            secondary: "#D6C7D4",
          },
        });
        return;
      }

      if (!cartData || cartData.length === 0) {
        toast.error("Select a menu before submitting an order", {
          style: {
            border: "1px solid #FA8072",
            padding: "16px",
            color: "#FA8072",
          },
          iconTheme: {
            primary: "#FA8072",
            secondary: "#D6C7D4",
          },
        });
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/order/addOrderweb/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            restaurantFK: restaurantId,
            tableNb: tableNb,
            allergy: selectedAllergy === "Other" ? otherAllergy : selectedAllergy,
            payMethod: paymentMethod,
          }),
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || responseData.error);
      } else {
        if (paymentMethod === "Cash") {
          await cashPayment();
          const generatedOrderId = responseData.data._id;
          localStorage.setItem("orderId", generatedOrderId);
          localStorage.setItem("orderTime", new Date().toISOString());
        } else if (paymentMethod === "Credit card") {
          const generatedOrderId = responseData.data._id;
          localStorage.setItem("orderId", generatedOrderId);
          navigate("/payment");
          return;
        }

        setOrderId(responseData.data._id);
        setShowPaymentPopup(false);
        setShowRatingTipPopup(true);
      }

    } catch (err) {
      toast.error(err.message, {
        style: {
          border: "1px solid #FA8072",
          padding: "16px",
          color: "#FA8072",
        },
        iconTheme: {
          primary: "#FA8072",
          secondary: "#D6C7D4",
        },
      });
    }
  };

  const cashPayment = async () => {
    try {
      const userId = localStorage.getItem("userId");

      if (!userId) {
        throw new Error("User ID is not found. Please log in again.");
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/order/cash/method/paymentweb/${userId}`,
        {
          method: "PUT",
          credentials: "include",
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || responseData.error);
      } else {
        toast.success("Payment confirmed and email sent", {
          style: {
            border: "1px solid #FA8072",
            padding: "16px",
            color: "#FA8072",
          },
          iconTheme: {
            primary: "#FA8072",
            secondary: "#D6C7D4",
          },
        });

        setOrderId(localStorage.getItem("orderId"));
        setShowRatingTipPopup(true);
      }
    } catch (err) {
      toast.error(err.message, {
        style: {
          border: "1px solid #FA8072",
          padding: "16px",
          color: "#FA8072",
        },
        iconTheme: {
          primary: "#FA8072",
          secondary: "#D6C7D4",
        },
      });
    }
  };

  const handleTipChange = (event) => {
    setTip(event.target.value);
  };

  const handleCancel = () => {
    navigate('/');
  };

  const handleFeedbackSubmit = async () => {
    try {
      console.log('Submitting feedback with the following data:');
      console.log('User ID:', userId);
      console.log('Restaurant ID:', restaurantId);
      console.log('Order ID:', orderId);
      console.log('Comment:', comment);
      console.log('Rating:', rating);
      console.log('Tip:', tip);

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/avis/resturant/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          comment,
          note: rating,
          restaurantFK: restaurantId,
          orderFK: orderId,
        }),
      });

      const responseData = await response.json();
      console.log('Response data:', responseData);

      if (!response.ok) {
        throw new Error(responseData.message || responseData.error);
      }

      toast.success('Thanks for your feedback!', {
        style: {
          border: '1px solid #FA8072',
          padding: '16px',
          color: '#FA8072',
        },
        iconTheme: {
          primary: '#FA8072',
          secondary: '#D6C7D4',
        },
      });

      setShowRatingTipPopup(false);
      navigate('/');
    } catch (err) {
      console.error('Error submitting feedback:', err);
      toast.error(err.message, {
        style: {
          border: '1px solid #FA8072',
          padding: '16px',
          color: '#FA8072',
        },
        iconTheme: {
          primary: '#FA8072',
          secondary: '#D6C7D4',
        },
      });
    }
  };

  return (
    <div className={styles.backdrop} onClick={props.closeModal} style={props.style}>
      {showPaymentPopup && (
        <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
          <div className={styles.top}>
            <h4 className={styles.h1}>{t('allergy.title')}</h4>
            <img src={close} alt="Close" onClick={props.closeModal} />
          </div>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Allergy</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              placeholder="Allergy"
              label="Allergy"
              onChange={handleChange}
              defaultValue=""
            >
              {allergies.map((el) => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedAllergy === "Other" && (
                    <>
                      <input
                        type="text"
                        className={styles.input}
                        placeholder="Other"
                        onChange={async (e) => {
                          const otherAllergy = e.target.value;
                          setOtherAllergy(otherAllergy);

                          if (otherAllergy.trim()) { // Vérifie que le champ n'est pas vide
                            try {
                              const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/user/${userId}/allergy`, {
                                allergies: otherAllergy
                              });
                              console.log('Allergie ajoutée:', response.data);
                            } catch (error) {
                              console.error('Erreur lors de l\'ajout de l\'allergie:', error);
                            }
                          }
                        }}
                      />
                      <br />
                    </>
                  )}


          <div className={styles.paymentMethods}>
            <h4 className={styles.h1}>{t('allergy.paymentMethod')}</h4>
            <div className={styles.oneMethod}>
              <div className={styles["checkbox-wrapper-19"]}>
                <input
                  type="checkbox"
                  id="credit"
                  ref={creditRef}
                  onClick={() => {
                    cashRef.current.checked = false;
                    handlePaymentMethodChange("Credit card");
                  }}
                />
                <label htmlFor="credit" className={styles["check-box"]}></label>
              </div>
              <img src={creditImage} alt="Credit card" />
              <p className={styles.p}>{t('allergy.creditCard')}</p>
            </div>
            <div className={styles.oneMethod}>
              <div className={styles["checkbox-wrapper-19"]}>
                <input
                  type="checkbox"
                  id="cash"
                  ref={cashRef}
                  onClick={() => {
                    creditRef.current.checked = false;
                    handlePaymentMethodChange("Cash");
                  }}
                />
                <label htmlFor="cash" className={styles["check-box"]}></label>
              </div>
              <img src={CashImage} alt="Cash" />
              <p className={styles.p}>{t('allergy.cash')}</p>
            </div>
            <div className={styles.oneMethod}>
              <div className={styles["checkbox-wrapper-19"]}>
                <input
                  type="checkbox"
                  id="paypal"
                  ref={paypalRef}
                  onClick={() => {
                    creditRef.current.checked = false;
                    cashRef.current.checked = false;
                    handlePaymentMethodChange("PayPal");
                  }}
                />
                <label htmlFor="paypal" className={styles["check-box"]}></label>
              </div>
              <img src={PayPalImage} alt="PayPal" />
              <p className={styles.p}>{t('allergy.paypal')}</p>
            </div>
          </div>

          <div className={styles.confirmOrderBtnContainer}>
            <button className={styles["button-3"]} onClick={handleSubmit}>
              {t('allergy.confirmOrder')}
            </button>
          </div>
        </div>
      )}
      {!showPaymentPopup && paymentMethod === 'Credit card' && (
        <Elements stripe={stripePromise}>
          <PaymentForm
            selectedAllergy={selectedAllergy}
            otherAllergy={otherAllergy}
            paymentMethod={paymentMethod}
            restaurantId={restaurantId}
            userId={userId}
            tableNb={tableNb}
            setShowRatingTipPopup={setShowRatingTipPopup}
            setOrderId={setOrderId}
          />
        </Elements>
      )}
      {!showPaymentPopup && paymentMethod === 'PayPal' && (
        <PayPalForm amount={amount} cartOrderId={localStorage.getItem('cartOrderId')} />
      )}
      {showRatingTipPopup && (
        <div className={styles.modalOverlay} onClick={closeModal}>
          <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
            <div className={styles.top}>
              <h4>{t('allergy.rateExperience')}</h4>
              <img src={close} alt="Close" onClick={props.closeModal} />
            </div>
            <div className={styles.content}>
              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={24}
                activeColor="#ffd700"
                value={rating}
                classNames={styles.reactStars}
              />
              <textarea
                placeholder={t('allergy.leaveComment')}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                className={styles.textArea}
              />
              <h6>{t('allergy.tip')}</h6>
              <div className={styles.tips}>
                {['5$', '10$', '15$'].map((amount) => (
                  <button
                    key={amount}
                    className={tip === amount ? styles.selectedTip : styles.tip}
                    onClick={() => setTip(amount)}
                  >
                    {amount}
                  </button>
                ))}
                <input
                  type="number"
                  placeholder={t('allergy.tipOther')}
                  value={tip}
                  onChange={(e) => setTip(e.target.value)}
                  className={styles.tipInput}
                />
              </div>
            </div>
            <div className={styles.actions}>
              <button className={styles.cancelButton} onClick={handleCancel}>{t('allergy.cancel')}</button>
              <button onClick={handleFeedbackSubmit} className={styles.submitButton}>{t('allergy.submit')}</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Allergy;
