import React from 'react'
import Signin from '../components/Login/Signin'

const LoginPage = () => {
  return (
    <div className='page'>
      <Signin />
    </div>
  )
}

export default LoginPage