import React from 'react'
import SignUP from '../components/Sign up/SignUP'

const SignupPage = () => {
  return (
    <div className='page'>
      <SignUP />
    </div>
  )
}

export default SignupPage