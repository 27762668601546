// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.code-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

}

.code {
  caret-color: transparent;
  border-radius: 5px;
  font-size: 22px;
  height: 50px;
  width: 50px;
  border: 1px solid #FA8072;
  margin: 1%;
  text-align: center;
  font-weight: bold;
  -moz-appearance: textfield;
}

.code::-webkit-outer-spin-button,
.code::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.code:valid {
  border-color: #FA8072;
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.25);
}

.info {
  background-color: #eaeaea;
  display: inline-block;
  padding: 10px;
  line-height: 20px;
  max-width: 400px;
  color: #777;
  border-radius: 5px;
}

@media (max-width: 600px) {
  .code-container {
    flex-wrap: wrap;
  }

  .code {
    font-size: 60px;
    height: 80px;
    max-width: 70px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ActivateAccount/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;;AAEhB;;AAEA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,WAAW;EACX,yBAAyB;EACzB,UAAU;EACV,kBAAkB;EAClB,iBAAiB;EACjB,0BAA0B;AAC5B;;AAEA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE,qBAAqB;EACrB,gDAAgD;AAClD;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,aAAa;EACb,iBAAiB;EACjB,gBAAgB;EAChB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,YAAY;IACZ,eAAe;EACjB;AACF","sourcesContent":[".code-container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin: 20px 0;\n\n}\n\n.code {\n  caret-color: transparent;\n  border-radius: 5px;\n  font-size: 22px;\n  height: 50px;\n  width: 50px;\n  border: 1px solid #FA8072;\n  margin: 1%;\n  text-align: center;\n  font-weight: bold;\n  -moz-appearance: textfield;\n}\n\n.code::-webkit-outer-spin-button,\n.code::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n.code:valid {\n  border-color: #FA8072;\n  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.25);\n}\n\n.info {\n  background-color: #eaeaea;\n  display: inline-block;\n  padding: 10px;\n  line-height: 20px;\n  max-width: 400px;\n  color: #777;\n  border-radius: 5px;\n}\n\n@media (max-width: 600px) {\n  .code-container {\n    flex-wrap: wrap;\n  }\n\n  .code {\n    font-size: 60px;\n    height: 80px;\n    max-width: 70px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
