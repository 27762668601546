import Profile from '../components/Profile/Profile'

const ProfilePage = () => {
  return (
    <div className='page'>
     <Profile />
    </div>
  )
}

export default ProfilePage