// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search_subHeading__saKvh {
    margin-bottom: 1rem;

  }
  
  .search_subHeadingText__H-78d {
    color: black;
    font-size: 20px; /* Set font size here */
  }
  
  .search_spoon__img__5zOvd {
    /* Ensure styles for your image if necessary */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Menu/search.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;;EAErB;;EAEA;IACE,YAAY;IACZ,eAAe,EAAE,uBAAuB;EAC1C;;EAEA;IACE,8CAA8C;EAChD","sourcesContent":[".subHeading {\n    margin-bottom: 1rem;\n\n  }\n  \n  .subHeadingText {\n    color: black;\n    font-size: 20px; /* Set font size here */\n  }\n  \n  .spoon__img {\n    /* Ensure styles for your image if necessary */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subHeading": `search_subHeading__saKvh`,
	"subHeadingText": `search_subHeadingText__H-78d`,
	"spoon__img": `search_spoon__img__5zOvd`
};
export default ___CSS_LOADER_EXPORT___;
