import CheckoutFormWrapper from "../components/Menu/CheckoutFormWrapper"

const PaymentPage = () => {
  return (
    <div className='page'>
        <CheckoutFormWrapper />
    </div>
  )
}

export default PaymentPage