import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IconButton, Badge, Dialog, DialogTitle, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { MdNotifications, MdDoNotDisturb } from 'react-icons/md';
import AllergiesModal from './AllergiesModal'; // Assurez-vous que ce chemin est correct
import { Button } from '@mui/material';

const AllergiesBadge = ({ userId }) => {
  const [allergies, setAllergies] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const fetchAllergies = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/user/${userId}/allergies`);
        setAllergies(response.data);
      } catch (error) {
        console.error('Failed to fetch allergies', error);
      }
    };

    fetchAllergies();
  }, [userId]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const refreshAllergies = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/user/${userId}/allergies`);
      setAllergies(response.data);
    } catch (error) {
      console.error('Failed to fetch allergies', error);
    }
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    refreshAllergies(); // Refresh allergies after closing the modal
  };

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <Badge badgeContent={allergies.length} color="secondary">
          <img src="/img/diet.png" alt="Allergy Icon" style={{ width: 44, height: 44 }} />
        </Badge>
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Allergies</DialogTitle>
        <List>
          {allergies.length > 0 ? (
            allergies.map((allergy, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <MdDoNotDisturb />
                </ListItemIcon>
                <ListItemText primary={allergy || "Unspecified Allergy"} />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemIcon>
                <MdDoNotDisturb />
              </ListItemIcon>
              <ListItemText primary="No allergies recorded" />
            </ListItem>
          )}
        </List>
        <Button  style={{
    backgroundColor: 'salmon',
    color: 'white',
    marginTop: 20,
    width: '50%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  }}onClick={handleModalOpen}>Add Allergy</Button>
      </Dialog>
      <AllergiesModal open={modalOpen} handleClose={handleModalClose} refreshAllergies={refreshAllergies} />
    </>
  );
};

export default AllergiesBadge;
