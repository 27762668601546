import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navs/Navs";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./DishDetails.css";
import { useTranslation } from "react-i18next";
import DefaultImage from "../../../assets/default.jpg";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { Language } from "@material-ui/icons";
import LanguageSwitcher from "../../LanguageSwitcher";
import { useLanguage } from "../../LanguageContext";

function DishDetails(props) {
  const { productId } = useParams();
  const [dish, setDish] = useState(null);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [ingredientItems, setIngredientItems] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [userId, setUserId] = useState(null);
  const [similarProducts, setSimilarProducts] = useState([]);
  const navigate = useNavigate();
  const restaurantId = useSelector((state) => state.restaurant.restaurantId);
  const { t } = useTranslation();
  const storedRestaurantId = localStorage.getItem("restaurantId");
  const [restaurantStatus, setRestaurantStatus] = useState("open");
  const [cartOrderId, setCartOrderId] = useState(null);
  const i18nextLng = localStorage.getItem("i18nextLng");
  const language = localStorage.getItem("language");
  // const [listAr, setListAr]=useState(translatedProductsToArabe || []);
  // const { translatedProductsToArabe, isArabic } = useLanguage();

  const [translatedProductsToArabe, setTranslatedProductsToArabe] = useState([
    null,
  ]);
  const [translatedProductsToFrench, setTranslatedProductsToFrench] = useState([
    null,
  ]);

  const [translatedProductsToEnglish, setTranslatedProductsToEnglish] =
    useState([null]);

  const [similarProductsEnglish, setSimilarProductsEnglish] = useState([]);
  const [similarProductsFrench, setSimilarProductsFrench] = useState([]);
  const [similarProductsArabe, setSimilarProductsArabe] = useState([]);
  const fetchToArabe_ = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/product/translate/arabic/${productId}`
        )
        .then((res) => {
          setTranslatedProductsToArabe(res?.data);
          localStorage.setItem("language", "ar");
          localStorage.setItem("i18nextLng", "ar");
          // window.location.reload();
        });
    } catch (error) {
      console.log(error);
    }
  };
  const fetchToFrench_ = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/product/translate/french/${productId}`
        )
        .then((res) => {
          setTranslatedProductsToFrench(res?.data);
          localStorage.setItem("language", "fr");
          localStorage.setItem("i18nextLng", "fr");
          // window.location.reload();
        });
    } catch (error) {
      console.log(error);
    }
  };
  const fetchToEnglish_ = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/product/translate/english/${productId}`
        )
        .then((res) => {
          setTranslatedProductsToEnglish(res?.data);
          localStorage.setItem("language", "en");
          localStorage.setItem("i18nextLng", "en");
          // window.location.reload();
        });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSimilarToEnglish = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/product/products/translate/english/${productId}/similar`
        )
        .then((res) => {
          setSimilarProductsEnglish(res?.data);
          localStorage.setItem("language", "en");
          localStorage.setItem("i18nextLng", "en");
          // window.location.reload();
        });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSimilarToFrench = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/product/products/translate/french/${productId}/similar`
        )
        .then((res) => {
          setSimilarProductsFrench(res?.data);
          localStorage.setItem("language", "fr");
          localStorage.setItem("i18nextLng", "fr");
          // window.location.reload();
        });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSimilarToArabe = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/product/products/translate/arabic/${productId}/similar`
        )
        .then((res) => {
          setSimilarProductsArabe(res?.data);
          localStorage.setItem("language", "ar");
          localStorage.setItem("i18nextLng", "ar");
          // window.location.reload();
        });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchByLanguage = () => {
    if (i18nextLng === "ar") {
      fetchToArabe_();
    } else if (i18nextLng === "en") {
      fetchToEnglish_();
    } else {
      fetchToFrench_();
    }
  };

  const fetchSimilarByLanguage = () => {
    if (i18nextLng === "ar") {
      fetchSimilarToArabe();
    } else if (i18nextLng === "en") {
      fetchSimilarToEnglish();
    } else {
      fetchSimilarToFrench();
    }
  };

  // useEffect(() => {}, [listAr]);
  useEffect(() => {
    const fetchSimilarProducts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/product/products/${productId}/similar`
        );
        setSimilarProducts(response.data.products);
      } catch (error) {
        console.error("Error fetching similar products:", error);
        toast.error("Failed to fetch similar products.");
      }
    };
    fetchSimilarProducts();
  }, [productId]);

  useEffect(() => {
    try {
      const tokenLoginString = localStorage.getItem("tokenLogin");
      if (tokenLoginString) {
        const user = JSON.parse(tokenLoginString);
        if (user && user._id) {
          setUserId(user._id);
        } else {
          console.error("User object does not have ID.");
          toast.error("User data is malformed.");
        }
      } else {
        console.error("User not authenticated.");
      }
    } catch (error) {
      console.error("Failed to parse user data:", error);
    }
  }, []);

  const fetchDishDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/product/find/item/${productId}`
      );
      setDish(response.data);
    } catch (error) {
      console.error("Error fetching dish details:", error);
    }
  };
  useEffect(() => {
    // fetchToArabe();
    //  fetchToFrench();
    fetchByLanguage();
    fetchSimilarByLanguage();
    fetchDishDetails();
  }, [productId]);

  useEffect(() => {}, [productId]);

  useEffect(() => {
    const fetchIngredients = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/ingredient/retrieve/disponible/ingredient/by/Product/${productId}`
        );
        setSelectedIngredients(response.data);
      } catch (error) {
        console.error("Error fetching ingredients:", error);
      }
    };
    fetchIngredients();
  }, [productId]);

  useEffect(() => {
    const fetchIngredientItems = async (ingredientId) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/item/retrieve/visible/item/by/ingredient/${ingredientId}`
        );
        setIngredientItems((prevItems) => ({
          ...prevItems,
          [ingredientId]: response.data,
        }));
      } catch (error) {
        console.error(
          `Error fetching items for ingredient ${ingredientId}:`,
          error
        );
      }
    };

    selectedIngredients.forEach((ingredient) => {
      fetchIngredientItems(ingredient._id);
    });
  }, [selectedIngredients]);

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleIngredientToggle = (ingredientId, itemId) => {
    setSelectedIngredients((prevSelected) => {
      const ingredientIndex = prevSelected.findIndex(
        (ing) => ing._id === ingredientId
      );
      if (ingredientIndex === -1) {
        // Ingredient not selected yet, add it
        return [...prevSelected, { _id: ingredientId, items: [itemId] }];
      } else {
        const newSelected = [...prevSelected];
        if (!newSelected[ingredientIndex].items) {
          newSelected[ingredientIndex].items = [];
        }
        const itemIndex = newSelected[ingredientIndex].items.indexOf(itemId);
        if (itemIndex === -1) {
          // Item not selected yet, add it
          newSelected[ingredientIndex].items.push(itemId);
        } else {
          // Item already selected, remove it
          newSelected[ingredientIndex].items.splice(itemIndex, 1);
        }
        return newSelected;
      }
    });
  };
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/restaurant/retrieve/${storedRestaurantId}`
      )
      .then((response) => {
        setRestaurantStatus(response.data.status);
        console.log("Restaurant status:", restaurantStatus);
      })

      .catch((error) =>
        console.error("Error fetching restaurant status:", error)
      );
  }, [storedRestaurantId]);
  const handleAddToCart = async () => {
    const userId = localStorage.getItem("userId");
    const userPass = userId || "000000000000000000000000";
    const storedRestaurantId = localStorage.getItem("restaurantId");
    const tableNb = localStorage.getItem("tableNb") || 0; // Utilisez 0 si tablenb n'est pas trouvé

    if (!storedRestaurantId) {
      console.error("Restaurant ID is missing");
      toast.error("Restaurant ID is missing");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/cart/addtocartweb/${userPass}`,
        {
          productFK: productId,
          quantity,
          restaurantFK: storedRestaurantId,
          tableNb: tableNb,
          selectedIngredients: selectedIngredients.map((ing) => ({
            ingredientId: ing._id,
            items: ing.items,
          })),
        },
        { withCredentials: true }
      );

      if (response.status === 200) {
        toast.success("Product added to cart!");
        const updatedCartItems = JSON.parse(localStorage.getItem("cart")) || [];
        const existingItemIndex = updatedCartItems.findIndex(
          (item) => item._id === productId
        );

        if (existingItemIndex !== -1) {
          updatedCartItems[existingItemIndex].quantity += quantity;
        } else {
          updatedCartItems.push({
            _id: productId,
            name: dish.name,
            price: dish.price,
            photo: dish.photo,
            quantity,
            tableNb: tableNb,
          });
        }
        localStorage.setItem("cart", JSON.stringify(updatedCartItems));
        console.log("Adding product to cart:", dish);
        const cartId = response.data.cartId; // Assurez-vous que c'est la bonne clé de la réponse
        if (cartId) {
          console.log("Cart ID:", cartId);
          localStorage.setItem("cartTrash", cartId);
        }
        const cartOrderId = response.data.latestUserCartOrder
          ? response.data.latestUserCartOrder._id
          : null;
        if (cartOrderId) {
          localStorage.setItem("cartOrderId", cartOrderId); // Store the CartOrder ID in localStorage
        }
      } else {
        throw new Error("Failed to add product to cart");
      }
    } catch (error) {
      console.error("Error adding product to cart:", error);
      toast.error(
        error.message || "An error occurred while adding product to cart"
      );
    }
  };

  const fetchToArabe = () => {
    fetchToArabe_();
    fetchSimilarToArabe();
  };
  const fetchToFrench = () => {
    fetchToFrench_();
    fetchSimilarToFrench();
  };
  const fetchToEnglish = () => {
    fetchToEnglish_();
    fetchSimilarToEnglish();
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  if (!dish) {
    return <div>Loading...</div>;
  }

  const { name, photo, description, storyTelling, price, promotion } = dish;

  // const { fetchToArabe, translatedProductsToArabe,  isArabic } = props;

  const ingredientsStyle = {
    listStyle: "none",
    paddingLeft: 0,
  };

  const checkboxStyle = {
    marginRight: "10px",
  };

  const isArabic = localStorage.getItem("ar");

  return (
    <>
      <Navbar
        fetchToArabe={fetchToArabe}
        fetchToFrench={fetchToFrench}
        fetchToEnglish={fetchToEnglish}
      />
      {/* <div className="container d-flex justify-content-end mt-5 pt-5">
        <div className="mt-5">
          <button
            onClick={() => {
              fetchToArabe();
            }}
          >
            arabic
          </button>{" "}
          &nbsp;
          <button
            onClick={() => {
              fetchToFrench();
            }}
          >
            french
          </button>
          &nbsp;
          <button
            onClick={() => {
              fetchToEnglish();
            }}
          >
            english
          </button>
        </div>
      </div> */}
      <div className="container">
        {i18nextLng === "en" && (
          <div className="row" style={{ marginTop: "200px" }}>
            {translatedProductsToEnglish?.map((item, index) => (
              <React.Fragment key={index}>
                <div className="col-md-6">
                  <div className="images p-3">
                    <div className="text-center p-4">
                      {item?.photo != null ? (
                        <img
                          id="main-image"
                          alt="product"
                          src={
                            `${process.env.REACT_APP_BACKEND_URL}/uploads/product/` +
                            item?.photo
                          }
                          className="w-100"
                          style={{ borderRadius: 25 }}
                        />
                      ) : (
                        <img
                          id="main-image"
                          alt="product"
                          src={DefaultImage}
                          className="w-100"
                          style={{ borderRadius: 25 }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="main-description px-4">
                    <h5
                      className="text-uppercase"
                      style={{
                        color: "salmon",
                        fontSize: "25px",
                        fontWeight: "bold",
                      }}
                    >
                      {item?.name}
                    </h5>
                    <p className="display-6">${price}</p>
                    {promotion > 0 && (
                      <p
                        className="promotion text-danger"
                        style={{ fontSize: "20px" }}
                      >
                        Save {promotion}%!
                      </p>
                    )}

                    <ul style={{ listStyle: "none", paddingLeft: 0 }}>
                      {selectedIngredients.map((ingredient) => (
                        <li key={ingredient._id}>
                          <div>
                            <p
                              className="ingredients-title mb-1"
                              style={{ fontWeight: "bold" }}
                            >
                              {ingredient.libelle}
                            </p>
                            {ingredientItems[ingredient._id] && (
                              <ul style={{ marginLeft: "20px" }}>
                                {ingredientItems[ingredient._id].map((item) => (
                                  <div key={item._id}>
                                    <input
                                      type="checkbox"
                                      style={{ marginTop: "30px" }}
                                      id={item._id}
                                      onChange={() =>
                                        handleIngredientToggle(
                                          ingredient._id,
                                          item._id
                                        )
                                      }
                                    />
                                    <label
                                      style={{ marginTop: "-30px" }}
                                      htmlFor={item._id}
                                    >
                                      {item.title} : ${item.price}
                                    </label>
                                  </div>
                                ))}
                              </ul>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>

                    <Tabs>
                      <TabList>
                        <Tab> {t("dish.description")} </Tab>
                        <Tab>{t("dish.storyTelling")} </Tab>
                      </TabList>

                      <TabPanel>
                        <p
                          className="description mb-4 text-justify"
                          style={{ fontSize: "0.9rem", color: "grey" }}
                        >
                          {item?.description}
                        </p>
                      </TabPanel>
                      <TabPanel>
                        <p
                          className="description mb-4 text-justify"
                          style={{ fontSize: "0.9rem", color: "grey" }}
                        >
                          <ReactReadMoreReadLess
                            charLimit={500}
                            readMoreText={` ▼`}
                            readLessText={` ▲`}
                          >
                            {item?.storyTelling || null}
                          </ReactReadMoreReadLess>
                        </p>
                      </TabPanel>
                    </Tabs>
                  </div>
                  <div className="buttons d-flex mx-4">
                    <div style={{ marginRight: "1rem" }}>
                      <FontAwesomeIcon
                        icon={faHeart}
                        style={{
                          cursor: "pointer",
                          color: "salmon",
                          fontSize: "24px",
                        }}
                      />
                    </div>
                    <div className="block mr-3">
                      <button
                        style={{
                          color: "white",
                          background:
                            restaurantStatus === "closed" ? "gray" : "salmon",
                          cursor:
                            restaurantStatus === "closed"
                              ? "not-allowed"
                              : "pointer",
                        }}
                        onClick={
                          restaurantStatus === "open"
                            ? handleAddToCart
                            : () => toast.error("Restaurant is closed")
                        }
                        disabled={restaurantStatus === "closed"}
                      >
                        {t("dish.addToCart")}
                      </button>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        )}
        {i18nextLng === "ar" && (
          <div className="row" style={{ marginTop: "200px" }}>
            {translatedProductsToArabe?.map((item, index) => (
              <React.Fragment key={index}>
                <div className="col-md-6">
                  <div className="images p-3">
                    <div className="text-center p-4">
                      {item?.photo != null ? (
                        <img
                          id="main-image"
                          alt="product"
                          src={
                            `${process.env.REACT_APP_BACKEND_URL}/uploads/product/` +
                            item?.photo
                          }
                          className="w-100"
                          style={{ borderRadius: 25 }}
                        />
                      ) : (
                        <img
                          id="main-image"
                          alt="product"
                          src={DefaultImage}
                          className="w-100"
                          style={{ borderRadius: 25 }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="main-description px-4">
                    <h5
                      className="text-uppercase"
                      style={{
                        color: "salmon",
                        fontSize: "25px",
                        fontWeight: "bold",
                      }}
                    >
                      {item?.name}
                    </h5>
                    <p className="display-6">${price}</p>
                    {promotion > 0 && (
                      <p
                        className="promotion text-danger"
                        style={{ fontSize: "20px" }}
                      >
                        Save {promotion}%!
                      </p>
                    )}

                    <ul style={{ listStyle: "none", paddingLeft: 0 }}>
                      {selectedIngredients.map((ingredient) => (
                        <li key={ingredient._id}>
                          <div>
                            <p
                              className="ingredients-title mb-1"
                              style={{ fontWeight: "bold" }}
                            >
                              {ingredient.libelle}
                            </p>
                            {ingredientItems[ingredient._id] && (
                              <ul style={{ marginLeft: "20px" }}>
                                {ingredientItems[ingredient._id].map((item) => (
                                  <div key={item._id}>
                                    <input
                                      type="checkbox"
                                      style={{ marginTop: "30px" }}
                                      id={item._id}
                                      onChange={() =>
                                        handleIngredientToggle(
                                          ingredient._id,
                                          item._id
                                        )
                                      }
                                    />
                                    <label
                                      style={{ marginTop: "-30px" }}
                                      htmlFor={item._id}
                                    >
                                      {item.title} : ${item.price}
                                    </label>
                                  </div>
                                ))}
                              </ul>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>

                    <Tabs>
                      <TabList>
                        <Tab> {t("dish.description")} </Tab>
                        <Tab>{t("dish.storyTelling")} </Tab>
                      </TabList>

                      <TabPanel>
                        {!item.description ? (
                          <p
                            className="description mb-4 text-justify"
                            style={{ fontSize: "0.9rem", color: "grey" }}
                          >
                            ...
                          </p>
                        ) : (
                          <p
                            className="description mb-4 text-justify"
                            style={{ fontSize: "0.9rem", color: "grey" }}
                          >
                            {item?.description}
                          </p>
                        )}
                      </TabPanel>
                      <TabPanel>
                        <p
                          className="description mb-4 text-justify"
                          style={{ fontSize: "0.9rem", color: "grey" }}
                        >
                          {item?.storyTelling === null ? null : (
                            <ReactReadMoreReadLess
                              charLimit={500}
                              readMoreText={` ▼`}
                              readLessText={` ▲`}
                            >
                              {item?.storyTelling}
                            </ReactReadMoreReadLess>
                          )}
                        </p>
                      </TabPanel>
                    </Tabs>
                  </div>
                  <div className="buttons d-flex mx-4">
                    <div style={{ marginRight: "1rem" }}>
                      <FontAwesomeIcon
                        icon={faHeart}
                        style={{
                          cursor: "pointer",
                          color: "salmon",
                          fontSize: "24px",
                        }}
                      />
                    </div>
                    <div className="block mr-3">
                      <button
                        style={{
                          color: "white",
                          background:
                            restaurantStatus === "closed" ? "gray" : "salmon",
                          cursor:
                            restaurantStatus === "closed"
                              ? "not-allowed"
                              : "pointer",
                        }}
                        onClick={
                          restaurantStatus === "open"
                            ? handleAddToCart
                            : () => toast.error("Restaurant is closed")
                        }
                        disabled={restaurantStatus === "closed"}
                      >
                        {t("dish.addToCart")}
                      </button>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        )}
        {i18nextLng === "fr" && (
          <div className="row" style={{ marginTop: "200px" }}>
            {translatedProductsToFrench?.map((item, index) => (
              <React.Fragment key={index}>
                <div className="col-md-6">
                  <div className="images p-3">
                    <div className="text-center p-4">
                      {item?.photo != null ? (
                        <img
                          id="main-image"
                          alt="product"
                          src={
                            `${process.env.REACT_APP_BACKEND_URL}/uploads/product/` +
                            item?.photo
                          }
                          className="w-100"
                          style={{ borderRadius: 25 }}
                        />
                      ) : (
                        <img
                          id="main-image"
                          alt="product"
                          src={DefaultImage}
                          className="w-100"
                          style={{ borderRadius: 25 }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="main-description px-4">
                    <h5
                      className="text-uppercase"
                      style={{
                        color: "salmon",
                        fontSize: "25px",
                        fontWeight: "bold",
                      }}
                    >
                      {item?.name}
                    </h5>
                    <p className="display-6">${price}</p>
                    {promotion > 0 && (
                      <p
                        className="promotion text-danger"
                        style={{ fontSize: "20px" }}
                      >
                        Save {promotion}%!
                      </p>
                    )}

                    <ul style={{ listStyle: "none", paddingLeft: 0 }}>
                      {selectedIngredients.map((ingredient) => (
                        <li key={ingredient._id}>
                          <div>
                            <p
                              className="ingredients-title mb-1"
                              style={{ fontWeight: "bold" }}
                            >
                              {ingredient.libelle}
                            </p>
                            {ingredientItems[ingredient._id] && (
                              <ul style={{ marginLeft: "20px" }}>
                                {ingredientItems[ingredient._id].map((item) => (
                                  <div key={item._id}>
                                    <input
                                      type="checkbox"
                                      style={{ marginTop: "30px" }}
                                      id={item._id}
                                      onChange={() =>
                                        handleIngredientToggle(
                                          ingredient._id,
                                          item._id
                                        )
                                      }
                                    />
                                    <label
                                      style={{ marginTop: "-30px" }}
                                      htmlFor={item._id}
                                    >
                                      {item.title} : ${item.price}
                                    </label>
                                  </div>
                                ))}
                              </ul>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>

                    <Tabs>
                      <TabList>
                        <Tab> {t("dish.description")} </Tab>
                        <Tab>{t("dish.storyTelling")} </Tab>
                      </TabList>

                      <TabPanel>
                        <p
                          className="description mb-4 text-justify"
                          style={{ fontSize: "0.9rem", color: "grey" }}
                        >
                          {item?.description}
                        </p>
                      </TabPanel>
                      <TabPanel>
                        <p
                          className="description mb-4 text-justify"
                          style={{ fontSize: "0.9rem", color: "grey" }}
                        >
                          <ReactReadMoreReadLess
                            charLimit={500}
                            readMoreText={` ▼`}
                            readLessText={` ▲`}
                          >
                            {item?.storyTelling}
                          </ReactReadMoreReadLess>
                        </p>
                      </TabPanel>
                    </Tabs>
                  </div>
                  <div className="buttons d-flex mx-4">
                    <div style={{ marginRight: "1rem" }}>
                      <FontAwesomeIcon
                        icon={faHeart}
                        style={{
                          cursor: "pointer",
                          color: "salmon",
                          fontSize: "24px",
                        }}
                      />
                    </div>
                    <div className="block mr-3">
                      <button
                        style={{
                          color: "white",
                          background:
                            restaurantStatus === "closed" ? "gray" : "salmon",
                          cursor:
                            restaurantStatus === "closed"
                              ? "not-allowed"
                              : "pointer",
                        }}
                        onClick={
                          restaurantStatus === "open"
                            ? handleAddToCart
                            : () => toast.error("Restaurant is closed")
                        }
                        disabled={restaurantStatus === "closed"}
                      >
                        {t("dish.addToCart")}
                      </button>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
      <div className="container my-5">
        <h2 hidden={similarProducts.length === 0} className="display-5 mb-4">
          {t("dish.recommend")}
        </h2>
        {i18nextLng === "en" && (
          <div className="d-flex">
            {similarProductsEnglish.length != 0
              ? similarProductsEnglish.map((product) => (
                  <div key={product._id} className="text-center m-4">
                    {product?.photo != null ? (
                      <img
                        src={
                          `${process.env.REACT_APP_BACKEND_URL}/uploads/product/` +
                          product?.photo
                        }
                        alt={product.name}
                        className="similar-product-image"
                        onClick={() => navigate(`/dish-details/${product._id}`)}
                      />
                    ) : (
                      <img
                        id="similar-product-image"
                        alt="product"
                        src={DefaultImage}
                        className="w-100"
                        style={{ borderRadius: 25 }}
                      />
                    )}

                    <h5 className="mt-3">
                      <b>{product.name}</b>
                    </h5>
                    <p style={{ fontSize: 28 }}>${product.price}</p>
                  </div>
                ))
              : null}
          </div>
        )}
        {i18nextLng === "fr" && (
          <div className="d-flex">
            {similarProductsFrench.length != 0
              ? similarProductsFrench.map((product) => (
                  <div key={product._id} className="text-center m-4">
                    {product?.photo != null ? (
                      <img
                        src={
                          `${process.env.REACT_APP_BACKEND_URL}/uploads/product/` +
                          product?.photo
                        }
                        alt={product.name}
                        className="similar-product-image"
                        onClick={() => navigate(`/dish-details/${product._id}`)}
                      />
                    ) : (
                      <img
                        id="similar-product-image"
                        alt="product"
                        src={DefaultImage}
                        className="w-100"
                        style={{ borderRadius: 25 }}
                      />
                    )}

                    <h5 className="mt-3">
                      <b>{product.name}</b>
                    </h5>
                    <p style={{ fontSize: 28 }}>${product.price}</p>
                  </div>
                ))
              : null}
          </div>
        )}

        {i18nextLng === "ar" && (
          <div className="d-flex">
            {similarProductsArabe.length != 0
              ? similarProductsArabe.map((product) => (
                  <div key={product._id} className="text-center m-4">
                    {product?.photo != null ? (
                      <img
                        src={
                          `${process.env.REACT_APP_BACKEND_URL}/uploads/product/` +
                          product?.photo
                        }
                        alt={product.name}
                        className="similar-product-image"
                        onClick={() => navigate(`/dish-details/${product._id}`)}
                      />
                    ) : (
                      <img
                        id="similar-product-image"
                        alt="product"
                        src={DefaultImage}
                        className="w-100"
                        style={{ borderRadius: 25 }}
                      />
                    )}

                    <h5 className="mt-3">
                      <b>{product.name}</b>
                    </h5>
                    <p style={{ fontSize: 28 }}>${product.price}</p>
                  </div>
                ))
              : null}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default DishDetails;
