// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Checkout_btn__1J5zL {
    background-color: var(--primary-blue);
    color : white ;
    outline: none;
    border: 1px solid black;
    border-radius: 5px;
    margin-top: 30px;
    padding: 10px;
}

.Checkout_btn__1J5zL:hover {
    background-color: green;
}

.Checkout_form__suwv2 {
    width: 90%;
    margin: auto;
    margin-top: 30px;
    
}`, "",{"version":3,"sources":["webpack://./src/components/Menu/Checkout.module.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,cAAc;IACd,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,gBAAgB;;AAEpB","sourcesContent":[".btn {\n    background-color: var(--primary-blue);\n    color : white ;\n    outline: none;\n    border: 1px solid black;\n    border-radius: 5px;\n    margin-top: 30px;\n    padding: 10px;\n}\n\n.btn:hover {\n    background-color: green;\n}\n\n.form {\n    width: 90%;\n    margin: auto;\n    margin-top: 30px;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `Checkout_btn__1J5zL`,
	"form": `Checkout_form__suwv2`
};
export default ___CSS_LOADER_EXPORT___;
