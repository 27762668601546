import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { AiOutlineUser, AiOutlineShoppingCart } from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Toaster, toast } from "react-hot-toast";
import { useMediaQuery } from "@react-hook/media-query";
import { cartActions } from "../../store/cartSlice";
import OriginalLogo from "../../assets/logo11.png";
import { faHeart, faBell, faStar } from "@fortawesome/free-solid-svg-icons";
import fr from "./../../assets/fr.svg";
import gb from "./../../assets/gb.svg";
import pt from "./../../assets/pt.svg";
import tn from "./../../assets/tn.svg";
import { authActions } from "../../store/authSlice";
import styles from "./Navs.module.css";
import { Dropdown, Collapse, initMDB } from "mdb-ui-kit";
import axios from "axios";
import { AiFillStar } from "react-icons/ai";
import LanguageSwitcher from "../LanguageSwitcher";
import { useTranslation } from "react-i18next";
import soundAudio from "../Navs/audio/notif.mp3";

const Navs = (props) => {
  const {
    fetchToFrench,
    fetchToEnglish,
    fetchToArabe,
    fetchToFrench_,
    fetchToEnglish_,
    fetchToArabe_,
    fetchSimilarToEnglish,
    fetchSimilarToFrench,
    fetchSimilarToArabe,
    fetchToArabeResto,
    fetchToFrenchResto,
    fetchToEnglishResto,
    
  } = props;
  const isMobile = useMediaQuery({ maxWidth: 500, maxHeight: 900 });
  initMDB({ Dropdown, Collapse });

  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const isGoogleAuth = useSelector((state) => state.auth.isGoogleAuthenticated);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.auth.userEmail);
  const token = localStorage.getItem("token");
  const cartItems = useSelector((state) => state.cart.cartData || []);
  const [anchorEl, setAnchorEl] = useState(null);
  const [restaurants, setRestaurants] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [mobileView, setMobileView] = useState(false);
  const matches = useMediaQuery("(max-width: 800px)");
  const [fix, setFix] = useState(false);
  const [result, setResult] = useState({ text: "No result" });
  const [redirect, setRedirect] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [userFullName, setUserFullName] = useState("");
  const [userImage, setUserImage] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);
  const [cartTrash, setCartTrash] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);
  const { t } = useTranslation();

  const totalItems = useSelector((state) =>
    state.cart.cartData.reduce((total, item) => item.quantityProduct, 0)
  );

  useEffect(() => {
    const sound = new Audio(soundAudio);

    const fetchNotifications = async () => {
      const userId = localStorage.getItem("userId");
      if (!userId) return;
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/notification/notifications/${userId}`
        );
        const data = await response.json();

        if (data.length > notifications.length && notifications.length > 0) {
          sound.play();
        }
        setNotifications(data);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    const fetchReviews = async () => {
      const userId = localStorage.getItem("userId");
      if (!userId) return;
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/avis/avis/${userId}`
        );

        setReviews(response.data);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    fetchNotifications();
    fetchReviews();
  }, []);

  useEffect(() => {}, [notifications, reviews.length]);

  // const toggleMenu = () => {
  //   setOpenMenu(!openMenu);
  //   if (isMobile) {
  //     setOpenCart(false);
  //     setShowSettings(false);
  //   }
  // };

  const toggleMobileView = () => {
    setMobileView(!mobileView);
  };

  const toggleSettings = () => {
    setShowSettings(!showSettings);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setRedirect(true);
      fetchRestaurants();
    }
  };

  const fetchRestaurants = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/menu/search?term=${searchTerm}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Erreur lors de la récupération des données");
      }
      const data = await response.json();
      console.log("Données des restaurants récupérées :", data);
      setRestaurants(data);
      if (redirect) {
        navigate("/search-results");
      }
    } catch (error) {
      console.error("Erreur:", error);
    }
  };

  useEffect(() => {
    fetchRestaurants();
  }, [redirect, searchTerm]);

  useEffect(() => {
    const setFixed = () => {
      if (window.scrollY > 0) {
        setFix(true);
      } else {
        setFix(false);
      }
    };

    const onScroll = () => {
      setFixed();
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = localStorage.getItem("userId");
        if (!userId) {
          throw new Error("User ID not found in localStorage");
        }
        const url = `${process.env.REACT_APP_BACKEND_URL}/user/getById/${userId}`;
        const response = await fetch(url, {
          credentials: "include",
        });
        const userData = await response.json();
        console.log("UserData:", userData);
        if (response.ok && userData) {
          setUserFullName(`${userData.firstName} ${userData.lastName}`);
          fetchUserImage();
        } else {
          throw new Error("Invalid user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (isAuth || isGoogleAuth) {
      fetchUserData();
    }
  }, [isAuth, isGoogleAuth, token]);

  const fetchUserImage = async () => {
    try {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        throw new Error("User ID not found in localStorage");
      }
      const url = `${process.env.REACT_APP_BACKEND_URL}/user/getImageByUserId/${userId}`;
      const response = await fetch(url, {
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch user image");
      }
      const imageBlob = await response.blob();
      const imageObjectURL = URL.createObjectURL(imageBlob);
      setUserImage(imageObjectURL);
    } catch (error) {
      console.error("Error fetching user image:", error);
    }
  };

  const logout = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/auth/logout`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message || responseData.error);
      } else {
        toast.success("Vous êtes déconnecté", {
          style: {
            border: "1px solid #FA8072",
            padding: "16px",
            color: "#FA8072",
          },
          iconTheme: {
            primary: "#FA8072",
            secondary: "#FFA0A0",
          },
        });
        dispatch(authActions.logout());
        navigate("/home");
      }
    } catch (error) {
      console.error("Erreur lors de la déconnexion :", error);
      toast.error("Erreur lors de la déconnexion", {
        style: {
          border: "1px solid #FA8072",
          padding: "16px",
          color: "#FA8072",
        },
        iconTheme: {
          primary: "#FA8072",
          secondary: "#FFA0A0",
        },
      });
    }
  };

  const navigateToProfile = () => {
    navigate("/home");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchRestaurants();
  };

  useEffect(() => {
    const dropdownElement = document.getElementById("navbarDropdownMenuAvatar");
    if (dropdownElement) {
      dropdownElement.addEventListener("click", function () {
        dropdownElement.classList.toggle("show");
      });
    }
  }, []);

  useEffect(() => {
    async function fetchLogo() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/logos/saumon`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch logo");
        }
        const blob = await response.blob();
        const imageUrl = URL.createObjectURL(blob);
        setLogoUrl(imageUrl);
      } catch (error) {
        console.error("Error fetching logo:", error);
      }
    }

    fetchLogo();
  }, []);

  const fetchCart = async () => {
    try {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        throw new Error("User not found in localStorage");
      }
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/cart/get/cartTrashweb/by/user/${userId}`,
        {
          credentials: "include",
        }
      );
      const responseData = await response.json();
      console.log(responseData);
      if (!response.ok) {
        throw new Error("cart doesn't exist");
      } else {
        dispatch(cartActions.setCart(responseData));
        setCartTrash(responseData?.cartData[0]?._id);
      }
    } catch (err) {
      toast.error(err.message, {
        style: {
          border: "1px solid #FA8072 ",
          padding: "16px",
          color: "#FA8072 ",
        },
        iconTheme: {
          primary: "#FA8072 ",
          secondary: "#D6C7D4",
        },
      });
    }
  };

  const total = useSelector((state) => state.cart.total);
  const handleCartClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCartClose = () => {
    setAnchorEl(null);
  };

  const handleToggleMenu = () => {
    setOpenMenu(!openMenu); // Toggle menu visibility
  };

  useEffect(() => {
    console.log("Menu open state:", openMenu); // This will log the current state when it changes
  }, [openMenu]);

  return (
    <div className={styles.navbarContainer}>
      <nav className={styles.navbar}>
        <div className={styles.logoContainer}>
          <a href="/">
            <img src={OriginalLogo} alt="Logo" className={styles.logo} />
          </a>
        </div>

        <div className={styles.navItems}>
          <NavLink to="/cart" className={styles.navLink}>
            <AiOutlineShoppingCart className={styles.icon} />
            {/* <span className={styles.notificationCount}>{totalItems}</span> */}
          </NavLink>
          {isAuth && (
            <div className={styles.dropdownWrapper}>
              {/* Profile Dropdown */}

              {/* Notification Dropdown */}
              <div
                className="dropdown"
                style={{ marginLeft: "30px", marginTop: "30px" }}
              >
                <a
                  href="#"
                  role="button"
                  aria-expanded={openNotifications ? "true" : "false"}
                  onClick={() => setOpenNotifications(!openNotifications)}
                >
                  <FontAwesomeIcon icon={faBell} className={styles.icon} />
                  <span className={styles.notificationCount}>
                    {notifications.length}
                  </span>
                </a>

                <ul
                  className={`dropdown-menu dropdown-menu-end ${
                    openNotifications ? "show" : ""
                  }`}
                  style={{ width: "300px" }}
                >
                  {notifications.length > 0 ? (
                    notifications.map((notification, index) => (
                      <div key={index} className={styles.notificationItem}>
                        {notification.orderFK.map((order, orderIndex) => (
                          <div key={orderIndex} className={styles.orderItem}>
                            <img
                              src={order.restaurantFK.images}
                              alt={order.restaurantFK.nameReas}
                              className={styles.notificationImage}
                            />
                            <div className={styles.notificationText}>
                              <h3 className={styles.notificationTitle}>
                                {order.restaurantFK.nameRes}
                              </h3>
                            </div>
                          </div>
                        ))}
                        <div className={styles.notificationText}>
                          <h3 className={styles.notificationTitles}>
                            {notification.title}
                          </h3>
                          <p className={styles.notificationBody}>
                            {notification.body}
                          </p>
                          <span className={styles.notificationTime}>
                            {new Date(notification.date).toLocaleString()}
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className={styles.noNotifications}>
                      No notifications found.
                    </p>
                  )}

                  {reviews.length > 0 &&
                    reviews.map((review, index) => (
                      <div key={index} className={styles.notificationItem}>
                        <div className={styles.notificationText}>
                          <h3 className={styles.notificationTitles}>Review</h3>
                          <p className={styles.notificationBody}>
                            {review.comment}
                          </p>
                          <div className={styles.stars}>
                            {[...Array(review.note)].map((_, i) => (
                              <FontAwesomeIcon key={i} icon={faStar} />
                            ))}
                          </div>
                          <span className={styles.notificationTime}>
                            {new Date(review.date).toLocaleString()}
                          </span>
                        </div>
                      </div>
                    ))}
                </ul>
              </div>
            </div>
          )}
          <LanguageSwitcher
            fetchSimilarToArabe={fetchSimilarToArabe}
            fetchSimilarToFrench={fetchSimilarToFrench}
            fetchSimilarToEnglish={fetchSimilarToEnglish}
            fetchToArabe={fetchToArabe}
            fetchToFrench={fetchToFrench}
            fetchToEnglish={fetchToEnglish}
            fetchToArabe_={fetchToArabe_}
            fetchToEnglish_={fetchToEnglish_}
            fetchToFrench_={fetchToFrench_}
            fetchToArabeResto={fetchToArabeResto}
            fetchToFrenchResto={fetchToFrenchResto}
            fetchToEnglishResto={fetchToEnglishResto}
          />

          {!isAuth && (
            <NavLink to="/login" className={styles.navLinkk}>
              <AiOutlineUser className={styles.icon} />
              Login
            </NavLink>
          )}
          {isAuth && (
            <ul
              className="dropdown"
              style={{
                marginTop: "30px",
                alignItems: "center",
                marginLeft: "30px",
              }}
            >
              <a
                data-mdb-dropdown-init
                className="dropdown-toggle d-flex align-items-center hidden-arrow me-3 link-salmon"
                href="/home"
                id="navbarDropdownMenuAvatar"
                role="button"
                aria-expanded="false"
                style={{ color: "salmon" }}
              >
                {userImage ? (
                  <img
                    src={userImage}
                    className="rounded-circle"
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                    alt="User"
                    loading="lazy"
                  />
                ) : (
                  <AiOutlineUser
                    className="rounded-circle"
                    style={{ height: "40px" }}
                  />
                )}
              </a>
              <ul
                className="dropdown-menu dropdown-menu-end"
                style={{
                  height: "auto",
                  backgroundColor: "black",
                  padding: "0.25rem 0",
                }}
                aria-labelledby="navbarDropdownMenuAvatar"
              >
                <li>
                  <div
                    className="dropdown-item"
                    style={{
                      fontSize: "15px",
                      textDecoration: "none",
                      color: "white",
                    }}
                  >
                    <AiOutlineUser className="me-2" />
                    <span>{userFullName}</span>
                  </div>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    style={{ fontSize: "12px", color: "white" }}
                    to="/profile"
                  >
                    <AiOutlineUser className="me-2" />
                    My profile
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    style={{ fontSize: "12px", color: "white" }}
                    to="/chatapp"
                  >
                    <AiOutlineUser className="me-2" />
                    Chat
                  </NavLink>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    style={{ fontSize: "12px", color: "white" }}
                    onClick={logout}
                  >
                    <AiOutlineUser className="me-2" />
                    Logout
                  </a>
                </li>
              </ul>
            </ul>
          )}
        </div>
      </nav>
    </div>
  );
};
export default Navs;
