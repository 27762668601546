import Menu from "../components/Menu/Menu"

const MenuPage = ({ fetchToArabe, translatedProductsToArabe, isArabic }) => {
  return (
    <div className='page'>
     <Menu />
    </div>
  )
}

export default MenuPage