// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../../node_modules/leaflet/dist/leaflet.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaflet-container {
  width: 100%;
  height: 100vh;
}
.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.contact-item p {
  margin: 0;
}

.text-salmon {
  color: #FA8072;
}
`, "",{"version":3,"sources":["webpack://./src/components/Home/Map/mapevent.css"],"names":[],"mappings":"AACA;EACE,WAAW;EACX,aAAa;AACf;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,cAAc;AAChB","sourcesContent":["@import url(\"~leaflet/dist/leaflet.css\");\n.leaflet-container {\n  width: 100%;\n  height: 100vh;\n}\n.contact-item {\n  display: flex;\n  align-items: center;\n  margin-bottom: 20px;\n}\n\n.contact-item p {\n  margin: 0;\n}\n\n.text-salmon {\n  color: #FA8072;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
