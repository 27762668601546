import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import ReactStars from "react-rating-stars-component";
import close from "../../assets/xmark-solid.svg";
import SafeProductsList from "../Menu/search/SafeProductsList";
import About from "../Menu/search/About";
import Dishes from "./search/Dishes";
import Home from "./search/Home";
import Testimonials from "./search/Testimonial";
import Footer from "../Footer/Footer";
import ChatButton from "../Menu/search/ChatButton";
import AllergiesModal from "../Profile/AllergiesModal"; // Adjust path as needed
import "../Home/AboutUs.module.css";
import "./item.css";
import "./search.module.css";
import styles from "./Cart.module.css";
import moment from "moment";
import Navs from "../Navs/Navs";

const SearchPage = (props) => {
  const navigate = useNavigate();
  const { restaurantId } = useParams();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [restaurant, setRestaurant] = useState(null);
  const [menuId, setMenuId] = useState("");
  const [showReviewPopup, setShowReviewPopup] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [tip, setTip] = useState("");
  const [safeProducts, setSafeProducts] = useState([]);
  const [error, setError] = useState(null);
  const [showAllergyModal, setShowAllergyModal] = useState(false);
  const [allergiesList] = useState([
    "No allergy",
    "Peanuts",
    "Tree Nuts",
    "Milk (Dairy)",
    "Gluten",
    "Lettuce",
    "Diabetes",
    "Soy",
    "Corn",
    "Shellfish",
    "Eggs",
    "Fish",
    "Shellfish",
    "Soy",
    "Wheat (Gluten)",
    "Sesame Seeds",
    "Mustard",
    "Sulphites",
    "Celery",
    "Lupin",
    "Corn",
    "Meat",
    "Fruits",
    "Vegetables",
  ]);
  const [selectedAllergy, setSelectedAllergy] = useState("");
  const [customAllergy, setCustomAllergy] = useState("");
  const [showCustomAllergyInput, setShowCustomAllergyInput] = useState(false);

  const ratingChanged = (newRating) => {
    setRating(newRating);
  };

  const closeModal = () => {
    setShowReviewPopup(false);
  };

  useEffect(() => {
    const fetchRestaurant = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/restaurant/retrieve/${restaurantId}`
        );
        setRestaurant(response.data);
      } catch (error) {
        console.error("Error fetching restaurant details:", error);
      }
    };

    if (restaurantId) {
      fetchRestaurant();
    }
  }, [restaurantId]);

  const i18nextLng = localStorage.getItem("i18nextLng");

  useEffect(() => {
    const promptForReview = async () => {
      const userId = localStorage.getItem("userId");
      const orderId = localStorage.getItem("orderId");
      const orderTime = localStorage.getItem("orderTime");

      if (userId && orderId && isAuthenticated && orderTime) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/avis/check/${userId}/${orderId}`
          );
          const { data } = response;
          if (!data.hasReviewed) {
            toast.info(
              "Please remember to leave a review for your recent order!"
            );

            const orderDateTime = moment(orderTime);
            const now = moment();
            const duration = moment.duration(now.diff(orderDateTime));
            const hoursPassed = duration.asHours();

            if (hoursPassed > 24) {
              setShowReviewPopup(true);
            }
          }
        } catch (error) {
          console.error("Failed to check review status:", error);
          toast.error("Failed to check review status.");
        }
      }
    };

    promptForReview();
  }, [isAuthenticated]);

  const handleFeedbackSubmit = async () => {
    const userId = localStorage.getItem("userId");
    const orderId = localStorage.getItem("orderId");
    try {
      const feedbackData = {
        comment: comment,
        note: rating,
        restaurantFK: restaurantId,
        orderFK: orderId,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/avis/resturant/${userId}`,
        feedbackData
      );
      const responseData = response.data;

      console.log("Response data:", responseData);
      toast.success("Thanks for your feedback!");

      setShowReviewPopup(false);
      navigate("/");
    } catch (err) {
      console.error("Error submitting feedback:", err);
      toast.error(
        "Failed to submit feedback: " + err.response?.data?.message ||
          err.message
      );
    }
  };

  const handleCancel = () => {
    navigate(`/search/${restaurantId}`);
  };

  useEffect(() => {
    const fetchSafeProducts = async () => {
      const userId = localStorage.getItem("userId");

      if (!userId || !menuId) {
        console.warn(
          "UserId or menuId is missing. Cannot fetch safe products."
        );
        return;
      }

      try {
        console.log(
          "Fetching safe products with userId:",
          userId,
          "and menuId:",
          menuId
        );
        const response = await axios.get(
          `http://localhost:7777/recommend/${userId}/${menuId}`
        );

        if (response.status !== 200) {
          throw new Error(
            `Failed to fetch safe products: ${response.status} ${response.statusText}`
          );
        }

        const data = response.data;
        console.log("Data received:", data);

        if (data.error) {
          console.log("API Error:", data.error);
          setError(data.error);
        } else if (!data.safe_products || data.safe_products.length === 0) {
          console.log("No safe products found.");
          setSafeProducts([]);
        } else {
          setSafeProducts(data.safe_products);
        }
      } catch (err) {
        console.error("Fetch error:", err.message);
        setError(`Fetch error: ${err.message}`);
      }
    };

    fetchSafeProducts();
  }, [menuId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const userId = localStorage.getItem("userId");
    if (!userId) {
      console.error("No user ID found");
      return;
    }

    const allergyToSend =
      selectedAllergy === "Other" ? customAllergy : selectedAllergy;
    if (!allergyToSend) {
      console.error("Allergy must not be empty");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/user/${userId}/allergy`,
        {
          allergies: allergyToSend,
        }
      );

      console.log("Response data:", response.data);

      setShowAllergyModal(false);
    } catch (error) {
      console.error(
        "Error adding allergy:",
        error.response ? error.response.data : error
      );
    }
  };

  useEffect(() => {
    const checkAllergyPrompt = async () => {
      const userId = localStorage.getItem("userId");
      if (userId) {
        try {
          // Vérifiez si l'utilisateur a déjà été invité à renseigner les allergies
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/user/user/${userId}/hasPrompted`
          );
          const hasPrompted = response.data.hasPrompted;

          // Si l'utilisateur n'a pas encore été invité, affichez le modal et marquez l'invitation comme effectuée
          if (!hasPrompted) {
            setShowAllergyModal(true);
            await axios.post(
              `${process.env.REACT_APP_BACKEND_URL}/user/user/${userId}/setPrompted`
            );
          }
        } catch (error) {
          console.error("Error checking allergy prompt:", error);
        }
      }
    };

    checkAllergyPrompt();
  }, []);

  const [translatedProductsToArabe, setTranslatedProductsToArabe] = useState(
    []
  );
  const [translatedProductsToFrench, setTranslatedProductsToFrench] = useState(
    []
  );
  const [translatedProductsToEnglish, setTranslatedProductsToEnglish] =
  useState([null]);

  const [translatedCategoriesToFrench, setTranslatedCategoriesToFrench] =
    useState([]);
  const [translatedCategoriesToArabe, setTranslatedCategoriesToArabe] =
    useState([]);
  const [translatedCategoriesToEnglish, setTranslatedCategoriesToEnglish] =
    useState([]);



  const fetchToArabe_ = () => {
    fetchToArabe__();
    fetchCategoriesAr();
  };
  
  const fetchToFrench_ = () => {
    fetchToFrench__();
    fetchCategoriesFr();
  };
  
  const fetchToEnglish_ = () => {
    fetchToEnglish__();
    fetchCategoriesEn();
  };

  const fetchToArabe__ = async () => {
    try {
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/product/translate/arabic`)
        .then((res) => {
          setTranslatedProductsToArabe(res?.data);
          // alert(JSON.stringify(res?.data));
          localStorage.setItem("language", "ar");
          localStorage.setItem("i18nextLng", "ar");
          // window.location.reload();
        });
    } catch (error) {
      console.log(error);
    }
  };
  const fetchToFrench__ = async () => {
    try {
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/product/translate/french`)
        .then((res) => {
          setTranslatedProductsToFrench(res?.data);
          localStorage.setItem("language", "fr");
          localStorage.setItem("i18nextLng", "fr");
          // window.location.reload();
        });
    } catch (error) {
      console.log(error);
    }
  };
  const fetchToEnglish__ = async () => {
    try {
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/product/translate/english/`)
        .then((res) => {
          setTranslatedProductsToEnglish(res?.data);
          localStorage.setItem("language", "en");
          localStorage.setItem("i18nextLng", "en");
          // window.location.reload();
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{},[menuId]);

  const fetchCategoriesAr = async () => {
    try {
      if (!menuId) {
        console.error("Menu ID is missing");
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/category/retrieve/translate/arabic/64ca398f0d72386e31a98bc6`
      );
      setTranslatedCategoriesToArabe(response?.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchCategoriesFr = async () => {
    try {
      if (!menuId) {
        console.error("Menu ID is missing");
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/category/retrieve/translate/french/64ca398f0d72386e31a98bc6`
      );
      setTranslatedCategoriesToFrench(response?.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };


  const fetchCategoriesEn = async () => {
    try {
      if (!menuId) {
        console.error("Menu ID is missing");
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/category/retrieve/translate/english/64ca398f0d72386e31a98bc6`
      );
      // alert(JSON.stringify(response?.data));
      setTranslatedCategoriesToEnglish(response?.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchByLanguage = () => {
    if (i18nextLng === "ar") {
      fetchToArabe_();
    } else if (i18nextLng === "en") {
      fetchToEnglish_();
    } else {
      fetchToFrench_();
    }
  };

  useEffect(() => {
    fetchByLanguage();

  }, []);

  return (
    <>
      <Navs
        fetchToArabe_={fetchToArabe_}
        fetchToEnglish_={fetchToEnglish_}
        fetchToFrench_={fetchToFrench_}
        translatedProductsToArabe={translatedProductsToArabe}
        translatedProductsToEnglish={translatedProductsToEnglish}
        translatedProductsToFrench={translatedProductsToFrench}
        translatedCategoriesToArabe={translatedCategoriesToArabe}
        translatedCategoriesToFrench={translatedCategoriesToFrench}
        translatedCategoriesToEnglish={translatedCategoriesToEnglish}
        fetchCategoriesAr={fetchCategoriesAr}
        fetchCategoriesFr={fetchCategoriesFr}
        fetchCategoriesEn={fetchCategoriesEn}
      />
      <About />
      <Home />
      <Dishes
        fetchToArabe_={fetchToArabe_}
        fetchToEnglish_={fetchToEnglish_}
        fetchToFrench_={fetchToFrench_}
        fetchCategoriesAr={fetchCategoriesAr}
        fetchCategoriesFr={fetchCategoriesFr}
        fetchCategoriesEn={fetchCategoriesEn}
        translatedProductsToArabe={translatedProductsToArabe}
        translatedProductsToEnglish={translatedProductsToEnglish}
        translatedProductsToFrench={translatedProductsToFrench}
        translatedCategoriesToArabe={translatedCategoriesToArabe}
        translatedCategoriesToFrench={translatedCategoriesToFrench}
        translatedCategoriesToEnglish={translatedCategoriesToEnglish}
      />
      <Testimonials />
      <Footer />
      <ChatButton />

      {/* {showReviewPopup && (
        <div className={styles.modalOverlay} onClick={closeModal}>
          <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
            <div className={styles.top}>
              <h4>Rate your experience</h4>
              <img src={close} alt="Close" onClick={closeModal} />
            </div>
            <div className={styles.content}>
              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={24}
                activeColor="#ffd700"
                value={rating}
                classNames={styles.reactStars}
              />
              <textarea
                placeholder="Leave a comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                className={styles.textArea}
              />
              <h6>Leave a tip</h6>
              <input
                type="number"
                placeholder="Amount"
                value={tip}
                onChange={(e) => setTip(e.target.value)}
                className={styles.input}
              />
              <button onClick={handleFeedbackSubmit} className={styles.submitButton}>Submit</button>
              <button onClick={closeModal} className={styles.cancelButton}>Cancel</button>
            </div>
          </div>
        </div>
      )} */}

      {showAllergyModal && (
        <AllergiesModal
          allergiesList={allergiesList}
          show={showAllergyModal}
          handleClose={() => setShowAllergyModal(false)}
          handleSubmit={handleSubmit}
          selectedAllergy={selectedAllergy}
          setSelectedAllergy={setSelectedAllergy}
          customAllergy={customAllergy}
          setCustomAllergy={setCustomAllergy}
          showCustomAllergyInput={showCustomAllergyInput}
          setShowCustomAllergyInput={setShowCustomAllergyInput}
        />
      )}
    </>
  );
};

export default SearchPage;
