import React from 'react'
import Home from '../components/Home/Home'
import Navs from '../components/Navs/Navs'

const HomePage = () => {
  return (
    <div className='page'>

        <Home />
    </div>
  )
}

export default HomePage