import React, { createContext, useState, useContext, useEffect } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import axios from 'axios';

import enTranslation from '../components/translations/en.json';
import arTranslation from '../components/translations/ar.json';
import frTranslation from '../components/translations/fr.json';
import esTranslation from '../components/translations/es.json';
import ptTranslation from '../components/translations/pt.json';
import chTranslation from '../components/translations/ch.json';
import inTranslation from '../components/translations/in.json';
import alTranslation from '../components/translations/al.json';

// Configuration d'i18next avec les ressources pour chaque langue
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslation },
      ar: { translation: arTranslation },
      fr: { translation: frTranslation },
      es: { translation: esTranslation },
      pt: { translation: ptTranslation },
      ch: { translation: chTranslation },
      in: { translation: inTranslation },
      al: { translation: alTranslation },
    },
    fallbackLng: 'en', // Langue par défaut si la langue sélectionnée n'est pas disponible
    interpolation: {
      escapeValue: false, // Pas d'échappement des caractères
    },
  });

const LanguageContext = createContext();

// Provider du contexte pour fournir la langue et la fonction de changement de langue
export const LanguageProvider = (props) => {
  const [language, setLanguage] = useState('fr');

  // Au montage du composant, on récupère la langue stockée dans localStorage
  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      setLanguage(storedLanguage);
    } else {
      setLanguage(i18n.language || window.navigator.language || 'en');
    }
  }, []);

  // Fonction pour changer la langue
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
    setLanguage(lng);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {props?.children}
    </LanguageContext.Provider>
  );
};

// Hook pour utiliser le contexte de langue dans n'importe quel composant
export const useLanguage = () => useContext(LanguageContext);

export default LanguageContext;
